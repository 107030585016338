@import "src/theme.scss";
.MuiAlert-root.notification_root {
  border-radius: 4px;
  border: 2px solid $white;
  font-weight: 700;
  font-family: $font-family-gothic;
  font-size: $text-size-lg;
  text-transform: uppercase;
}
.MuiAlert-standardSuccess.notification_success {
  background: $success;
  color: $white;
  & .MuiAlert-icon {
    color: $white;
  }
}
.MuiAlert-standardInfo.notification_info {
  background: $gray-3;
  color: $gray;
  & .MuiAlert-icon {
    color: $info;
  }
}
.MuiAlert-standardWarning.notification_warning {
  background: $warning;
  color: $black;
  & .MuiAlert-icon {
    color: $black;
  }
}
.MuiAlert-standardError.notification_error {
  background: $error;
  color: $white;
  & .MuiAlert-icon {
    color: $white;
  }
}

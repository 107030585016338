@import 'src/theme.scss';

.modifiers_wrapper {
  margin-bottom: 24px;
  .modifiers_section :global(.header_wrapper .title_wrapper .title.md) {
    font-size: $text-size-extra;
  }
  .modifiers_section :global(.section_description) {
    color: $gray;
    font-size: $text-size-sm;
    font-weight: 900;
    text-transform: uppercase;
  }
  .options_container {
    display: flex;
    flex-wrap: wrap;
    margin: 24px -4px -4px;

    .option_wrapper {
      width: 33.3%;
      padding: 4px;

      .cost {
        color: $primary;

        span {
          padding-left: 3px;
        }
      }

      @include laptop {
        width: 50%;
      }

      @include mobile-xl {
        width: 100%;
      }
    }
  }
 
}
.spinner {
  margin: 16px 0;
}

@import 'src/theme.scss';

.description {
  color: $black;
  font-size: $text-size-xl;
  padding-top: 10px;
  line-height: 22px;
}

.container {
  .back_link {
    text-transform: none;
    font-size: $text-size-lg;
    padding: 0;
    margin: 13px 0 0 -8px;
  }

  .products {
    margin: 20px 0 30px;

    .product + .product {
      margin-top: 8px;
    }
  }
}

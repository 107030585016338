@import 'src/theme.scss';
//InputField
.MuiFormControl-root {
  outline-color: $white;
  font-family: $font-family;
}
.MuiInputBase-root.input_root {
  border: 1px solid $gray-2;
  height: 64px;
  overflow: hidden;
  border-radius: 4px;
  background-color: $white;
  transition: border-color 0.4s ease-in-out;
  color: $black;
  &.outlined {
    border: 0;
  }
  &.readOnly {
    color: $gray;
  }
  &:hover {
    background-color: $white;
    border-color: $gray;
  }
  &:focus {
    background-color: $white;
    border-color: $gray;
  }
  &:active {
    background-color: $white;
    border-color: $gray;
  }
  input::placeholder {
    color: $gray;
    opacity: 1;
  }
}
.MuiInputBase-root.input_root.Mui-focused {
  background-color: $white;
}
.MuiInputBase-root.input_error {
  border-color: $error-color;
}
label.MuiFormLabel-root {
  font-family: $font-family;
}
.MuiFormLabel-root.MuiFormLabel-colorSecondary.MuiInputLabel-shrink {
  transform: translate(12px, 14px) scale(0.75);
}
.MuiFormLabel-root.MuiInputLabel-filled {
  transform: translate(12px, 24px) scale(1);
}
.MuiFormControl-root.Mui-focused {
  background-color: $white;
  border-color: $gray;
}
.MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-error {
  color: $error-color;
}
.MuiFormLabel-root.MuiFormLabel-colorSecondary {
  font-size: $text-size-md;
  color: $gray;
  display: block;
  @include mobile-xl {
    overflow-wrap: break-word;
    font-size: $text-size-md - 1px;
  }
}
.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-root.MuiFormLabel-filled {
  font-size: $text-size-md;
}
.MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-disabled {
  color: $gray;
}
.MuiFilledInput-root.Mui-disabled.input_root_disabled {
  border-color: $gray-2;
  background-color: $gray-3;
  &:hover {
    border-color: $gray-2;
    background-color: $gray-3;
  }
}

//MultilineInputField
.MuiInputBase-root.MuiInputBase-multiline {
  height: 120px;
}
//Search Input
.MuiFormControl-root.form_control_search {
  width: 100%;
}
.MuiInputBase-root.input_root.input_search {
  height: 56px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin: 0;
    color: $gray;
  }
  .MuiInputAdornment-positionEnd {
    color: $gray;
    svg {
      font-size: $text-size-xl;
    }
  }
  .MuiInputBase-inputAdornedStart {
    padding: 18px 8px 18px 8px;
    font-size: $text-size-md;
    font-family: $font-family;
  }
}

.MuiFormHelperText-root.Mui-error {
  font-family: $font-family;
}

.MuiFormControl-root.absolute_error .MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 64px;
}

@import 'src/theme.scss';
.wrapper {
  border: 2px solid $primary;
  border-radius: 4px;
  margin-top: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include mobile-xl {
    padding: 20px;
  }
  p {
    color: $black;
    font-size: $text-size-xl;
    line-height: 22px;
  }
}

div.confirmationSection{
  display: flex;
  gap: 15px;

  @include mobile-xl {
    flex-direction: column;
  }
}

button.button {
  position: relative;
  //margin-top: 24px;
  width: 50%;
  @include mobile-xl {
    width: 100%;
  }
}

.message {
  width: 50%;

  @include mobile-xl {
    width: 100%;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

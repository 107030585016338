$black: #2d2d2d;
$black-1: #000000;
$gray: #757575;
$gray-1: #E5E5E5;
$gray-2: #E0E0E0;
$gray-2-hover: #4b4949;
$gray-3: #F4F4F4;
$gray-4: #edeff3;
$gray-5: #828282;
$dark-gray: #5e5b5b;
$primary: #A51D36;
$primary-active: #651620;
$primary-hover: #C13051;
$primary-light:#ECDDDD;
$white: #ffffff;
$red: #c00a1e;
$green: #64A70B;
$orange: #FF8400;
$error: #D93849;
$warning: #FFD573;
$info:  #226db3;
$success: #31781F;

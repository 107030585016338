@import "src/theme.scss";

.modal_content {
    .invite_history_section {
        margin-bottom: 0;
        margin-top: 0;
    }

    .no_data {
        color: $gray;
        font-size: $text-size-xl;
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-flow: wrap;

        .invite_link {
            font-weight: bold;
            text-transform: unset;
            font-size: $text-size-xl;
            padding: 0;
        }
    }
}

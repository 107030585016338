@import 'src/theme.scss';

.modal .modal_container {
  margin: 32px;
  max-height: calc(100% - 144px);
  border-radius: 4px;
  overflow: inherit;
  position: relative;

  @include tablet-portrait {
    margin-left: 24px;
    margin-right: 24px;
    max-height: calc(100% - 54px);
    width: calc(100% - 48px);
  }

  @include mobile-xl {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
    border-radius: 4px;
  }

  @include mobile {
    margin-top: 65px;
    margin-bottom: 65px;
    max-height: calc(100% - 130px);
  }
}

.modal {
  & .modal_icon_close {
    position: absolute;
    z-index: 1;
    right: -26px;
    top: -26px;
    color: $primary;
    background: $white;
    border-radius: 50%;
    @include mobile-xl {
      right: -13px;
      top: -13px;
    }
    &:hover {
      cursor: pointer;
    }
    .modal_icon_button_close {
      width: 52px;
      height: 52px;
      @include mobile-xl {
        width: 40px;
        height: 40px;
      }
    }
    & svg {
      font-size: 24px;
    }
  }
  & .modal_scroll_wrapper {
    overflow-y: auto;
    border-radius: 4px;
    background-color: $gray-3;
    @include mobile-xl {
      flex: 1;
    }
  }
}

@media print {
  .MuiDialogTitle-root.dialog_header_root {
    padding: 0;
  }

  .modal_container .modal_scroll_wrapper {
    overflow-y: visible;
  }
}

.modal .dialog_header_root {
  position: relative;
  background-color: $white;
  display: flex;
  flex-direction: row;
  padding: 20px 20px 24px;
  @include mobile-xl {
    height: auto;
    min-height: 50px;
    padding: 0 16px;
  }

  & .modal_header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    @include mobile-xl {
      padding-top: 36px;
    }
    & .modal_header_title {
      position: relative;
      text-align: center;
      width: 100%;
      .title_wrapper {
        //border-radius: 25%;
        .title.lg {
          font-size: $header_size-extra - 8px;
          line-height: 34px;
          @include mobile-xl {
            font-size: $header-size-xl;
          }
        }
      }
      .brush_after {
        max-width: 200px;
        @include mobile-xl {
          width: 100%;
          max-width: 100px;
          height: 115%;
          transform: translateX(-70%);
        }
      }
      .brush_before {
        max-width: 200px;
        @include mobile-xl {
          width: 100%;
          max-width: 100px;
          height: 115%;
          transform: translateX(-40%);
        }
      }
      .modal_header_actions {
        position: absolute;
        left: 0;
      }
    }
  }
}
.MuiDialogContent-root.modal_body {
  padding: 16px 40px;
  overflow: visible;

  &.no_contrast {
    background: $white;
  }

  @include tablet {
    padding: 24px;
  }
  @include mobile-xl {
    padding: 8px 24px;
  }
}
.MuiDialogActions-root.modal_footer {
  padding: 24px 40px;
  background-color: $primary;
  justify-content: flex-start;
  border-radius: 0 0 4px 4px;
  &.no_contrast {
    background-color: $gray-3;
  }
  &.secondary_contrast {
    background-color: $white;
  }
  @include tablet {
    padding: 17px 24px;
  }
}

@import 'src/theme';

.section {
  margin: 0;
  padding: 40px 0;
}

.no_search_results {
  max-width: 375px;
  text-align: center;
  margin: 0 auto;
  padding: 120px 0;

  p {
    text-align: center;
    font-size: $text-size-xl + 6px;
    line-height: 32px;
    padding: 40px 0;
    color: $black;
  }
}

@import 'src/theme.scss';
$card-max-width: 330px;

.empty {
  max-width: 350px;
  div {
    padding: 0;
  }
}
.header {
  height: 135px;
  min-height: 80px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-xl {
    height: 80px;
  }
}
.disable {
  color: $primary;
}
.body {
  padding-bottom: 32px;
  padding-top: 8px;
  background-color: $white;
  overflow: auto;
  display: flex;
  justify-content: center;

  @include mobile-xl {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  @include mobile {
    flex: 1 auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

.primary {
  color: $primary;
  font-weight: 600;
}
.footer {
  background-color: $gray-3;
  padding: 24px;
  vertical-align: middle;
  text-align: center;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile-xl {
    padding: 24px 16px;
    flex-direction: column;
  }
  @include mobile {
    padding: 16px;
    min-height: unset;
  }

  .MuiButtonBase-root.btn_primary.btn_primary_sizeLarge.button {
    width: 100%;
    max-width: $card-max-width;
  }

  .button {
    min-width: 366px;
  }

  @include tablet {
    .button {
      min-width: 300px;
    }
  }

  .link {
    font-family: $font-family-gothic;
    font-size: $text-size-extra;
  }

  .icon {
    margin-left: 5px;
  }
}
.error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > span {
    max-width: 300px;
  }
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 100px 40px 0;

  &.modal_view {
    background: transparent;
    justify-content: center;
    padding: 0 8%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .inner_wrapper {
      position: relative;
      border: 2px solid $primary;
      border-radius: 8px;
      padding: 45px 24px 27px;
      background: $white;
      max-width: 400px;
      margin: 0 auto;
    }

    .icon {
      display: none;
    }
    .close_btn {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .description p {
      padding-bottom: 18px;
    }
  }
  .close_btn {
    display: none;
  }
}
.description {
  text-align: center;
  h2 {
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  p {
    padding-bottom: 32px;
    text-align: center;
    color: $gray-5;
    font-size: $text-size-md;
  }
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  & .marker {
    position: relative;
    top: unset;
    left: unset;
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
    & > div {
      width: 50px;
      height: 50px;
    }
    &:before {
      width: 80px;
      height: 80px;
    }
    &:after {
      width: 80px;
      height: 80px;
    }
  }
}
.btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    font-size: $text-size-extra;
    font-family: $font-family-gothic;
    padding: 0;
  }
}

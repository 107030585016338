@import 'src/theme.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .quantity_wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    font-family: $font-family-gothic;
    font-size: $text-size-extra;

    .quantity_label {
      color: $white;
      padding-right: 16px;
    }
  }

  .order_button {
    background-color: $white;
    color: $primary;
    padding: 14px 58px;
    transition: 0.4s ease-out;

    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
      color: $white;
    }

    .circular_progress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }

  @include mobile-xl {
    flex-direction: column;

    .order_button {
      width: 100%;
      margin-top: 16px;
      padding: 0;
    }
  }
}

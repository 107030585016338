@import 'src/theme.scss';

.cardNumber {
  font-weight: 500;
  font-size: $text-size-xl;
  margin-bottom: 8px;
}

.nameOnCard {
  font-size: $text-size-sm;
  color: $gray-2-hover;
}

.cardType {
  font-size: $text-size-sm;
  color: $gray-2-hover;
}

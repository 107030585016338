@import 'src/theme.scss';

.MuiDrawer-paper.drawer_paper {
  overflow-y: unset;
  background-color: $gray-3;

  @include mobile-xl {
    width: 100%;
  }

  .drawer_close_icon {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    @include mobile {
      right: 10px;
      top: 16px;
    }
  }

  .drawer_container {
    position: relative;
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $gray-3;
    overflow-y: auto;
    overflow-x: hidden;
    @include mobile-xl {
      width: 100%;
    }
    .drawer_header {
      background-color: $white;
      padding: 40px 40px 18px 40px;
      @include mobile-xl {
        padding: 16px 24px;
      }
      @include mobile {
        padding: 16px;
      }
      & > p {
        font-size: $text-size-sm;
        margin: 0;
        padding-top: 8px;
        color: $gray;
      }
    }

    .drawer_body {
      flex: 1;
      background-color: $gray-3;
      padding: 40px;
      box-sizing: content-box;

      @include mobile-xl {
        padding: 24px;
      }

      @include mobile {
        padding: 20px;
      }
    }
  }

  .drawer_footer {
    background-color: $primary;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    button span {
      font-family: $font-family-gothic;
      font-size: $text-size-extra;
    }

    @include mobile-xl {
      min-height: auto;
    }

    @include mobile {
      padding: 10px 20px;
    }
  }

  .drawer_footer.transparent_footer {
    background-color: transparent;
    border-top: 1px solid $gray-2;
  }
}

@import 'src/theme.scss';

.empty_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  @include mobile {
    padding-top: 30px;
  }
  h2 {
    padding-bottom: 8px;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 24px;
    color: $gray;
    font-size: $text-size-xl;
  }
  .empty_btn {
    width: 170px;
  }
}
.recipient {
  padding-top: 4px;
  font-weight: 600;
  color: $black;
  font-size: $text-size-md;
}
.item_title {
  display: inline-flex;
  align-items: flex-start;
  transition: color 0.2s ease-in-out;
  flex: 1;
  h4 {
    margin-bottom: 4px;
    color: inherit;
    flex: 0 70%;
    text-transform: capitalize;
    font-size: $text-size-xl;
    font-family: $font-family;
    font-weight: 400;
    @include mobile-xl {
      flex: 0 1 65%;
    }
    @include mobile {
      flex: 0 1 60%;
    }
  }
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}
.backdrop {
  width: 100%;
  height: 100%;
  background-color: $gray-3;
  opacity: 0.7;
  left: 0;
  top: 0;
  padding: 10px;
  display: block;
  position: absolute;
  z-index: 100;
}
.menu_item {
  border-bottom: 1px solid $gray-2;
  padding-top: 0;
  padding-bottom: 16px;
  & ~ .menu_item {
    padding: 16px 0;
  }
  &:last-child {
    border-bottom: unset;
  }
}
.item_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.left_side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
  @include mobile {
    margin-left: 8px;
  }
}
.price {
  font-weight: 800;
  font-size: $text-size-xl;
  line-height: 26px;
  padding-left: 8px;
  padding-top: 0;
  flex: 1 auto;
  justify-self: flex-end;
  text-align: right;
}
.description {
  color: $gray;
  font-size: $text-size-md;
  span {
    display: block;
  }
}
.wrapper {
  position: relative;
}
.alert {
  margin-bottom: 14px;
}
.quantity {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.quantity_title {
  font-weight: 700;
  font-size: $text-size-lg;
  text-transform: uppercase;
  color: $gray;
  margin-right: 12px;
  font-family: $font-family-gothic;
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  .link {
    font-size: $text-size-md;
    text-transform: capitalize;
    padding: 0;
    font-weight: 500;
    text-decoration: underline;
    min-width: auto;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
}
.body {
  padding: 46px 60px 0;
  height: 100%;
  flex: 1;
  .section {
    margin-bottom: 46px;

    &_rewards_icon {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }
  }

  @include tablet {
    padding-left: 26px;
    padding-right: 26px;
  }
  @include mobile-xl {
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.footer {
  padding: 24px 36px;
  background-color: $primary-light;
  .footer_btn {
    width: 100%;
  }

  .place_order_icon {
    margin-left: 8px;
  }
}
.glass {
  background-color: $black;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  .spinner {
    margin-top: 25vh;
    transform: scale(3, 3);
  }
}

.pay_options_btns {
  width: 100%;
  @include mobile-xl {
    button span {
      font-size: 15px !important;
    }

    .icon {
      display: none;
    }
  }

  .not_active_text {
    color: $gray;
    &.left {
      &:hover {
        border-right-color: transparent;
      }
    }
    &.right {
      &:hover {
        border-left-color: transparent;
      }
    }
  }

  &.pay_options_btns_hidden {
    visibility: hidden;
  }
}

.pay_options_wrapper {
  position: relative;
}

.pay_options_spinner {
  position: absolute;
  z-index: 10;
  transform: translate(0%, -25%);
}

.pay_restaurant_description {
  font-size: $text-size-md;
  line-height: $text-size-xl;
  font-weight: 400;
  color: $gray;
  margin-bottom: 16px;
}

.contactless_delivery {
  margin-top: 24px;
}

.hidden_payment_method {
  display: none;
}

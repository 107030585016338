@import 'src/theme.scss';
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    margin-bottom: 5px;
    text-transform: none;
  }
  h5 {
    text-transform: none;
    font-weight: 600;
  }

  .header_section {
    margin-top: 0;
  }
}
.deadline {
  letter-spacing: 0.01em;
  font-size: $text-size-md;
  color: $error-color;
  font-weight: bold;
}
.title {
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: none;
  font-weight: normal;
}

.instructions {
  padding: 24px 0;

  h5 {
    font-family: $font-family-gothic;
    font-size: $text-size-extra;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 3px;
  }

  p {
    color: $gray;
    font-size: $text-size-md;
    font-weight: normal;
  }

  p + h5 {
    margin-top: 15px;
  }
}

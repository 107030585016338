@import 'src/theme.scss';
.wrapper {
  font-size: $text-size-md;
  .link {
    color: $white;
    font-size: $text-size-md;
    line-height: unset;
    text-transform: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
    margin-left: 4px;
    &:hover {
      color: $gray-1;
    }
  }
}
.loading {
  width: 150px;
}
.city {
  display: flex;
  align-items: center;
}
.error_link {
  a {
    color: $error-color;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

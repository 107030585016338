@import 'src/theme.scss';

.container {
  & :global(.section_header .section_description) {
    color: $gray;

    @include mobile-xl {
      font-size: $text-size-md;
    }
  }
}

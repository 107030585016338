@import 'src/theme.scss';

.wrapper {
  width: 340px;
  min-height: 150px;
  border-radius: 8px;
  border: 2px solid $primary;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  @include mobile-xl {
    width: 100%;
  }
}
.body {
  padding: 24px;
  border-radius: 8px;
  min-height: 115px;
  height: 80%;
  background-color: $white;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .button_label {
    color: $white;
    letter-spacing: 0.05em;
    font-family: $font-family;
    font-size: $text-size-sm;
    font-weight: 600;
    text-decoration: none;
  }
}

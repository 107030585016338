@import 'src/theme.scss';
.description {
  p {
    color: $gray;
    font-size: $text-size-md;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-family: $font-family;
    text-transform: capitalize;
    padding-bottom: 8px;
  }
  .link {
    font-size: $text-size-sm;
  }
  @include mobile-xl {
    button {
      padding: 0;
      min-width: unset;
      margin-right: 8px;
    }
  }
}
.primary {
  color: $primary;
  font-weight: 600;
}
.empty_location {
  padding: 0 50px;
}

@import 'src/theme.scss';

.wrapper {
  font-family: $font-family;
}

.inputWrapper {
  flex-basis: calc(33% - 2px);
  margin-bottom: 8px;

  @include mobile-xl {
    margin-bottom: 16px;
  }
}

.input {
  input {
    font-size: $text-size-md;
    //font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  }
}

.inputGroupWrapper {
  display: flex;
  justify-content: space-between;

  @include mobile-xl {
    flex-direction: column;
  }
}

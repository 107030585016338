@import 'src/theme.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 360px;
  padding: 20px 0;

  & :global(.title_wrapper .title.extra) {
    font-size: 40px;
    line-height: 32px;
  }

  .description {
    font-size: $text-size-xl;
    line-height: 22px;
    color: $black-1;
    margin-top: 40px;
    text-align: center;
  }
}

.footer {
  width: 100%;
  text-align: center;

  .button {
    width: 100%;
    max-width: 360px;
  }
}

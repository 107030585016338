@import 'src/theme';

.container {
  position: relative;

  .nav_panel {
    top: $page-header-height;
    background: $primary;
    color: $text-color;
    box-shadow: none;

    &.with_notification {
      top: $page-header-height + $global-notification-height;
    }

    @include laptop {
      top: $page-header-height-tablet;

      &.with_notification {
        top: $page-header-height-tablet + $global-notification-height;
      }
    }

    @include mobile {
      top: $page-header-height-mobile;

      &.with_notification {
        top: $page-header-height-mobile + $global-notification-height;
      }
    }
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding: 18px;
  min-height: 70px;

  @include mobile-xl {
    padding: 10px;
    min-height: 55px;
  }
}

.slider_nav {
  display: block;
  padding: 18px;
  min-height: 70px;

  @include mobile-xl {
    padding: 10px;
    min-height: 55px;
  }
}

.nav,
.slider_nav {
  .nav_item,
  .touch .nav_item {
    color: $primary-light;
    margin: 4px;
    font-size: $text-size-lg;
    font-family: $font-family;
    line-height: 26px;
    padding: 16px 0;
    transition: none;
    border-radius: 2px;
    font-weight: 800;
    background-color: $primary-active;

    &:focus {
      background-color: $primary-active;
    }

    &.selected {
      background-color: $primary-light;
      color: $primary;
    }
  }
}

.nav {
  .nav_item:hover {
    background-color: $primary-hover;
    color: $white;
  }

  .nav_item:hover:not(.selected) {
    background-color: $gray-3;
    color: $gray;
    transition: none;
    box-shadow: none;

    > * {
      transition: none;
    }
  }
}

@import 'src/theme.scss';

.wrapper {
  margin: 40px 0;

  .link {
    font-size: $text-size-md;
    text-transform: capitalize;
    padding: 0;
    font-weight: 500;
    text-decoration: underline;
    min-width: auto;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.header_title {
  text-transform: uppercase;
  font-size: $text-size-extra;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-weight: 700;

  @include mobile-xl {
    font-size: $text-size-lg;
  }
}

.tooltip {
  color: $gray;
  font-size: $text-size-sm;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include mobile-xl {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.inputGroupWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @include mobile-xl {
    flex-direction: column;
  }
}

.button {
  max-width: 209px;
  position: relative;
  margin-top: 16px;
  @include mobile-xl {
    max-width: 100%;
  }
}

.giftCardNumber {
  flex: 1 66%;
  margin-right: 8px;

  @include mobile-xl {
    margin: 0 0 8px 0;
  }
}

.giftCardPin {
  flex-basis: calc(33% - 2px);
}

.buttonProgress {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 20px);
}

.checkbox_wrapper {
  margin-top: 24px;
}

@import 'src/theme.scss';
.MuiAppBar-root.app_bar_root {
  background-color: $primary;
  box-shadow: none;
  border-bottom: 1px solid $primary;
  z-index: 1150;
  &.with_notification {
    top: $global-notification-height;
  }
  .header_handheld_wrapper {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: $page-header-height-tablet;
    @include mobile {
      height: $page-header-height-mobile;
    }
    .handheld_menu_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .header_logo {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        @include mobile-xl {
          padding-right: 8px;
        }
      }
      .handheld_menu_panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-right: 16px;
        .handheld_search {
          margin-right: 8px;
        }
        .handheld_bag {
          margin-left: 8px;
        }
        .handheld_menu_btn.hide_block {
          width: 0;
        }
        .handheld_menu_btn {
          width: 54px;
          height: 54px;
          min-width: unset;
          border-color: $white;
          padding: 0;
          @include mobile {
            width: 40px;
            height: 40px;
            min-height: unset;
          }
        }
        .handheld_menu {
          .handheld_menu_items {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            transform: translateY(calc(100% + 1px));
            background-color: $primary;
            box-shadow: 0 24px 24px rgba(0, 0, 0, 0.1);
            text-align: left;
            .handheld_menu_item {
              min-height: 77px;
              padding: 16px;
              border-bottom: 1px solid $primary-light;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              width: 100%;
              .header_user {
                font-size: $text-size-sm;
                color: $white;
                font-family: $font-family-gothic;
                font-weight: 600;
                text-transform: uppercase;
              }
              .location_handheld_item,
              .order_type_handheld_item {
                padding-top: 8px;
              }
              .header_menu_items.handheld_mode {
                width: 100%;
                .header_menu_item {
                  font-size: $text-size-md;
                  border-bottom: 1px solid $primary-light;
                  width: 100%;
                  .header_menu_link {
                    min-height: 77px;
                    padding: 16px;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    text-transform: uppercase;
                    color: $white;
                    font-weight: 600;
                    .header_menu_item_icon {
                      color: $white;
                      display: inline-flex;
                    }
                  }
                }
              }
              &:last-child {
                border-bottom: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  .header_wrapper {
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: $page-header-height;
    .header_left_panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .header_location,
      .header_order_type_link {
        margin-left: 40px;
        font-size: $text-size-md;
        font-weight: 700;
        @include laptop(10px) {
          margin-left: 20px;
        }
      }
    }
    .header_right_panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .header_search {
        margin-right: 8px;
      }
      .header_user {
        text-transform: uppercase;
        font-weight: 700;
        font-size: $text-size-extra;
        font-family: $font-family-gothic;
        line-height: 24px;
        margin-right: 30px;
        margin-left: 8px;
        white-space: nowrap;
        text-align: right;
        width: auto;
        @include laptop {
          margin-right: 20px;
        }
      }
      .header_menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 40px;
        @include laptop {
          margin-right: 0;
        }
      }
      .header_menu_items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        .header_menu_item {
          margin-right: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 12px;
          }
          @include laptop {
            margin-right: 10px;
            white-space: nowrap;
          }
          &:last-child {
            margin-right: 0;
          }
          .header_menu_link {
            text-transform: uppercase;
            font-size: $text-size-extra;
            line-height: 24px;
            font-family: $font-family-gothic;
            color: $white;
            font-weight: 700;
            white-space: nowrap;
            margin: 0;
          }
        }
      }
    }
  }
}
.header_menu_item_with_icon {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

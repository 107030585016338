@import 'src/theme.scss';

.rewards_container {
  margin: 24px;

  h3 {
    color: $white;
    margin-bottom: 14px;
    font-family: $font-family;
    font-size: $header-size-md;
  }

  .rewards_title {
    text-align: center;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $primary-light;
    opacity: 0.2;
  }

  .rewards_footer {
    font-size: $text-size-sm;
    text-align: center;
    color: $white;
    margin: 15px;
  }
}

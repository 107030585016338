@import 'src/theme.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;

  span.image {
    flex: 1;

    img {
      border-radius: 4px;
    }
  }

  div.image {
    flex: 1;
    border-radius: 4px;
    padding: 90px 0;

    @media (max-width: 400px) {
      padding: 40px 0;
    }
  }

  .wrapper {
    flex: 1;
    padding: 0 50px;

    .name {
      font-size: $header-size-extra;
      line-height: 56px;

      @include mobile-xl {
        font-size: $header-size-xl;
        line-height: 36px;
      }
    }

    .cost_calories_wrapper {
      margin: 16px 0;
      color: $black;
      font-family: $font-family-gothic;
      font-size: $text-size-extra;
    }

    .description {
      color: $gray-5;
      font-size: $text-size-xl;
      line-height: 22px;
    }

    @include tablet {
      padding-right: 0;
    }

    @include tablet-portrait {
      padding: 10px 0 0;
    }

    @include mobile-xl {
      padding-bottom: 24px;
    }
  }

  @include tablet-portrait {
    flex-direction: column;
    align-items: flex-start;
  }
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  width: 100%;
  max-height: 400px;
  height: 100%;
  background-color: $white;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .close_btn {
    z-index: 10;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.order_btn {
  & .btn {
    height: 50px !important;
  }

  .disabled_btn {
    color: white !important;
    background-color: $gray !important;
  }
}

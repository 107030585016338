@import 'src/theme.scss';
.container {
  p {
    margin-bottom: 24px;
    color: $gray;
    letter-spacing: 0.01em;
    font-size: $text-size-md;
  }
}
.deadline {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;

  .date_container {
    width: 240px;

    .date {
      width: 100%;
    }

    @include mobile-xl {
      width: 50%;
    }

    .icon {
      color: $gray;
    }

    :global(.MuiFormLabel-root.Mui-focused) {
      color: $gray;
    }
  }

  .time_container {
    width: 120px;
    max-width: 120px;
    margin: 0 auto 0 8px;

    @include mobile-xl {
      width: 40%;
    }

    .time {
      height: 64px;
      background-color: transparent;
      &:focus {
        border-radius: 1px;
      }
    }
  }

  .date :global(.MuiFilledInput-underline) {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  .error {
    width: 100%;
    color: $red;
    margin: 8px 0 0;
  }
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-2;
  margin-bottom: 16px;
  &:last-child {
    border-bottom: none;
  }
  &:nth-last-child(2) {
    border-bottom: 2px solid $gray;
  }
}
.cell {
  font-size: $text-size-xl;
}
.bold {
  font-weight: 800;
}
.row.total {
  padding: 0;
  margin-bottom: 2px;
  font-weight: 800;
  font-family: $font-family-gothic;
  text-transform: uppercase;
  .cell {
    font-size: $header-size-xl;
  }
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('./fonts/Avenir-Regular/avenir-book-webfont.woff') format('woff'),
    url('./fonts/Avenir-Regular/avenir-book-webfont.eot'),
    url('./fonts/Avenir-Regular/avenir-book-webfont.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  src: local('Avenir'), url('./fonts/Avenir-Light/avenir-light.woff') format('woff'),
    url('./fonts/Avenir-Light/avenir-light.eot'), url('./fonts/Avenir-Light/avenir-light.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('./fonts/Avenir-Book/avenir-book.woff') format('woff'),
    url('./fonts/Avenir-Book/avenir-book.eot'), url('./fonts/Avenir-Book/avenir-book.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('./fonts/Avenir-Roman/avenir-roman.woff') format('woff'),
    url('./fonts/Avenir-Roman/avenir-roman.eot'), url('./fonts/Avenir-Roman/avenir-roman.ttf') format('truetype');
}
@font-face {
  font-family: 'New Gothic';
  font-weight: 700;
  src: local('New Gothic'), url('./fonts/News-Gothic-Condensed-Bold/news-gothic.woff') format('woff'),
    url('./fonts/News-Gothic-Condensed-Bold/news-gothic.woff2') format('woff2'),
    url('./fonts/News-Gothic-Condensed-Bold/news-gothic.eot'),
    url('./fonts/News-Gothic-Condensed-Bold/news-gothic.ttf') format('truetype');
}

@font-face {
  font-family: 'Museo Slab';
  font-weight: 900;
  src: url('./fonts/Museo-Slab-900/Museo_Slab_900-webfont.woff2') format('woff2'),
    url('./fonts/Museo-Slab-900/Museo_Slab_900-webfont.woff') format('woff');
}


@font-face {
  font-family: 'ActiveGrotesk-Medium';
  font-weight: 400;
  src: url('./fonts/AktivGrotesk-Medium/AktivGrotesk-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'ActiveGrotesk-XBold';
  font-weight: 700;
  src: url('./fonts/AktivGrotesk-XBold/AktivGrotesk-XBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NexaRustScriptB-Trial';
  font-weight: 400;
  src: url('./fonts/NexaRustScriptB-Trial-3/NexaRustScriptB-Trial-3.ttf') format('truetype');
}


@font-face {
  font-family: 'NexaRustSlab';
  font-weight: 400;
  src: url('./fonts/NexaRustSlab-Trial-Black3/NexaRustSlab-Trial-Black3.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Extra-Bold';
  font-weight: 800;
  font-style: normal;
	src: url('./fonts/Poppins/Poppins-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Poppins-Semi-Bold';
  font-weight: 600;
  font-style: normal;
	src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Poppins-Regular';
  font-weight: 400;
  font-style: normal;
	src: url('./fonts/Poppins/Poppins-Regular.ttf') format("truetype");
}
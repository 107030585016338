@import 'src/theme.scss';

.MuiPaper-root.card {
  &.hasHover:not(.isActive) {
    cursor: pointer;
  }

  .card_content {
    padding: 24px;
    background: white;
    &.hasLabel {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  background-color: $primary;
}

.MuiPaper-rounded.card {
  border-radius: 8px;
}

.MuiPaper-outlined.card {
  border: 2px solid $gray-2;
  &.isActive {
    border-color: $primary;
  }
  &:hover:not(.isActive) {
    border-color: $gray-2-hover;
  }
}

.cardBtn {
  position: relative;
  background-color: $primary;
  text-transform: uppercase;
  text-align: center;
  color: $white;
  line-height: 30px;
  font-weight: 600;
  font-size: $text-size-sm;
  transition: height 0.4s ease-out;
  letter-spacing: 0.05em;
  &:hover {
    cursor: pointer;
  }
}
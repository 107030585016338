@import 'src/theme.scss';
$numbers: 3, 2, 1;
.MuiPaper-root.MuiStepper-root.stepper_root.MuiStepper-horizontal {
  background-color: transparent;
  padding: 0;
  .MuiStepContent-root {
    border-left: 0;
    padding: 0;
    margin: 0;
  }
}
.MuiStep-root.MuiStep-vertical.MuiStep-alternativeLabel {
  padding-top: 16px;
  &:first-child {
    padding-top: 0;
  }
}
.MuiPaper-root.MuiStepper-root.stepper_root.MuiStepper-vertical {
  background-color: transparent;
  justify-content: flex-start;
  padding: 0;
}
.MuiStepLabel-root.MuiStepLabel-vertical.MuiStepLabel-alternativeLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  .step_icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-weight: bold;
      font-size: 40px;
    }
  }
  .MuiStepLabel-labelContainer {
    margin-left: 16px;
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      text-align: left;
      margin-top: 0;
      .stepper_desc {
        text-align: left;
      }
    }
  }
}
.MuiStepLabel-labelContainer {
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    .stepper_header {
      color: $gray;
      text-transform: none;
    }
    .stepper_desc {
      display: none;
    }
  }
}
.MuiStepLabel-labelContainer {
  .MuiStepLabel-label.MuiStepLabel-active {
    .stepper_header {
      color: $black;
    }
    .stepper_desc {
      display: block;
    }
  }
}
.MuiStepLabel-labelContainer {
  .MuiStepLabel-label.MuiStepLabel-completed {
    .stepper_header {
      color: $black;
    }
    .stepper_desc {
      display: block;
    }
  }
}
.stepper_header {
  margin-bottom: 8px;
  font-size: $text-size-xl;
  text-transform: capitalize;
  font-family: $font-family;
  font-weight: 700;
  @include mobile-xl {
    margin-bottom: 4px;
  }
}
.stepper_desc {
  text-align: center;
  color: $gray;
}
.step_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: $white;
  border-radius: 50%;
  border: 4px solid $gray;
  color: $white;
  z-index: 1;
  svg {
    font-weight: bold;
    font-size: 50px;
    color: $gray;
  }
}
.step_icon.active {
  background-color: $primary;
  border-color: $primary;
  svg {
    color: $white;
  }
}
.step_icon.completed {
  border-color: $primary;
  background-color: $primary;
  svg {
    color: $white;
  }
}
.step_connector.vertical {
  width: 4px;
  height: 100%;
  top: -50%;
  left: 24px;
  transform: translateX(-50%);
  @include mobile-xl {
    top: -40%;
  }
}
.step_connector.horizontal {
  top: 32px;
  left: calc(-50% + 32px);
  width: calc(100% - 64px);
}
.step_connector {
  height: 4px;
  background-color: $gray;
  position: absolute;
}
.step_connector.active {
  background-color: $primary;
}
.step_connector.completed {
  background-color: $primary;
}

@import "src/theme.scss";
.ComponentsPage {
  background-color: $gray-3;
}
.button_wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
  button {
    margin: 1rem;
  }
}
.modal_footer_custom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 100%;
  width: 100%;
}
.test_modal_custom_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 20px;
  img {
    width: 250px;
    margin-right: 20px;
    border-radius: 8px;
  }
}

.progress_line_wrapper {
  padding: 50px;

  &:last-child {
    background-color: $primary;
  }
}

.action_table_container {
  .action_table_btn {
    text-transform: unset;
    font-size: $text-size-md;
    font-weight: normal;

    &.delete {
      color: $gray;
    }
  }
}
@import 'src/theme.scss';
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-2;
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: $text-size-xl;
  @include mobile-xl {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.grow_cell {
  flex: 0 80%;
  @include mobile-xl {
    flex: 1 100%;
  }
}
.quantity {
  margin-right: 8px;
}
.cell {
  flex: 1;
  margin-left: 16px;
  text-align: right;
  @include mobile-xl {
    flex: 1 100%;
    margin-left: 0;
  }
}

@import 'src/theme.scss';
.wrapper_page-not-found {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  @include mobile-xl {
    padding: 60px 36px;
  }
  .page_not_found_logo {
    margin-bottom: 36px;
    @include mobile-xl {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page-not-found_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: $header-size-lg + 6px;
      font-weight: 700;
      line-height: 32px;
      font-family: $font-family-gothic;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 40px;
      @include mobile-xl {
        font-size: $text-size-xl;
        margin-bottom: 30px;
        text-align: center;
        line-height: 24px;
      }
    }
    a {
      width: 300px;
      text-decoration: none;
      @include mobile-xl {
        width: 100%;
      }
    }
  }
}

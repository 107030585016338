@import "src/theme.scss";

.page_container {
  padding: 0 100px;

  &.with_notification {
    margin-top: $global-notification-height;
  }

  @include laptop {
    padding: 0 24px;
    overflow: hidden;
  }

  @include mobile {
    padding: 0 16px;
  }
}

@import 'src/theme.scss';

.section {
  margin: 0;
}

.footer_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  width: 100%;

  .save_btn {
    width: 100%;
    padding: 20px 0;
  }

  .add_more_items_btn {
    margin-top: 16px;
    color: $white;
    line-height: 24px;

    &:hover {
      color: $white;
    }

    @include mobile {
      margin-bottom: 8px;
    }
  }

  .btn_spinner {
    position: absolute;
    margin-top: 4px;
    padding: 0;
  }
}

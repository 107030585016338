@import 'src/theme.scss';

.product_details_modal .back_to_menu {
  border-radius: 4px 4px 0 0;
}
.product_details_modal {
  :global(.MuiDialogContent-root.modal_body) {
    padding: 0;
  }
  :global(.MuiDialogContent-root.modal_body) > * {
    padding: 16px 40px;
    &:first-child {
      padding-bottom: 0;
    }
    &:last-child {
      padding-top: 0;
    }
    @include tablet {
      padding: 24px;
    }
    @include mobile-xl {
      padding: 8px 24px;
    }
  }
}
.product_details_modal {
  .name_section :global(.section_description) {
    color: $gray;
    font-size: $text-size-sm;
    font-weight: 900;
    text-transform: uppercase;
  }
  .name_section :global(.header_wrapper .title_wrapper .title.md) {
    font-size: $text-size-extra;
  }
  .name_wrapper {
    margin-top: 24px;
    padding-bottom: 60px;
    .input {
      min-width: 320px;
      @include mobile-xl {
        width: 100%;
        min-width: unset;
      }
    }
    @include mobile-xl {
      padding-bottom: 30px;
    }
  }
}

.error_container {
  margin-top: 10px;
  width: 100%;
}

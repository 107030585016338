@import 'src/theme';
@import 'src/mixins';

.modal_content {
  padding-bottom: 8px;

  .product_name {
    @include block-ellipsis(96px, 3);
    text-transform: none;
    margin: 0 10px 0 0;
    font-family: $font-family;
    font-size: $header-size-md;
    flex: 1;
  }

  .favorites_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    .add_to_bag_btn {
      margin-right: 24px;
      padding: 0 24px;

      @include tablet-portrait {
        margin-right: 10px;
      }
    }

    .modify_btn {
      font-family: $font-family-gothic;
      padding: 0;
    }
  }

  .no_data {
    color: $black-1;
    font-size: $text-size-xl;
    padding-bottom: 16px;
    text-align: center;
    line-height: 26px;
    font-weight: 800;

    .place_order_link {
      font-weight: bold;
      text-transform: unset;
      text-decoration: underline;
      font-size: $text-size-xl;
      top: -1px;
      padding: 0;
    }
  }

  .backdrop {
    z-index: 100;
  }

  @include mobile-xl {
    padding-top: 24px;

    .actions {
      flex-direction: column;
      justify-content: center;

      .add_to_bag_btn {
        margin: 0 0 10px 0;
        padding: 10px;
      }
    }
  }
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.radio {
  flex: 1 60%;
  margin-right: 16px;
  :global(.MuiFormGroup-root) {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    @include mobile-xl {
      flex-wrap: wrap;
    }
  }
  @include tablet-portrait {
    flex: 1 100%;
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
.radio_item {
  margin-right: 8px;
  min-width: 50px;
  max-width: 115px;
  width: 100%;
  .radio_btn {
    :global(.MuiRadio-root) {
      @include tablet {
        margin: 0;
        margin-right: 4px;
      }
    }
    :global(.MuiTypography-body1) {
      padding-left: 4px;

      p {
        font-size: $text-size-xl;
        text-transform: none;
      }
    }
  }
  @include laptop {
    width: 100%;
    max-width: 100%;
    min-width: unset;
    flex: 1 calc(100% / 4);
  }
  @include mobile-xl-horizontal {
    width: 100%;
    max-width: 115px;
    flex: 1 calc(100% / 4);
  }
  @include tablet-portrait {
    max-width: unset;
    width: 100%;
    flex: 1 calc(100% / 4);
    margin-bottom: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  @include mobile-xl {
    margin: 4px;
    width: 100%;
    max-width: unset;
    min-width: 115px;
    flex: 2 calc(100% / 4);
    &:last-child {
      margin-right: 4px;
    }
  }
}
.amount {
  display: flex;
  align-items: center;
  max-width: 180px;
  margin-left: auto;
  flex: 1 40%;
  padding-top: 24px;
  :global(.MuiInputBase-root.input_root) {
    height: 76px;
  }
  :global(.MuiFilledInput-input) {
    padding: 28px 16px;
    font-size: $text-size-xl;
  }
  @include tablet {
    flex: 1 1 100%;
  }
  @include tablet-portrait {
    max-width: 100%;
    width: 100%;
  }
  @include mobile-xl {
    width: 100%;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 4px;
    flex: 1 100%;
    max-width: unset;
  }
}
.amount_type {
  font-weight: 500;
  font-size: $text-size-xl;
  line-height: 28px;
  color: $gray;
  margin-left: auto;
  margin-right: 8px;
  @include mobile-xl {
    margin-right: 8px;
  }
}

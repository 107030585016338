@mixin block-ellipsis($height, $line-clamp:4) {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: $height;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clear-block-ellipsis() {
  max-height: 100%;
  overflow: visible;
  text-overflow: unset;
  -webkit-line-clamp: unset;
}
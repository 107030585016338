@import 'src/theme.scss';

.modal_content {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @include mobile-xl {
    padding: 0;
    margin-bottom: 16px;
  }

  .edit_profile_form {
    display: flex;
    flex-direction: column;
    width: 370px;

    .input_container {
      margin: 4px 0;
    }
  }

  .birthday_container {
    display: flex;
    align-items: center;
    margin: 4px 0;
    .delimiter {
      margin: 0 8px;

      @include mobile {
        margin: 0 4px;
      }
    }

    .birthday_label {
      font-weight: normal;
      font-size: $text-size-md;
      margin-right: 28px;

      @include mobile {
        margin-right: 0;
      }
    }
  }
}

.modal_footer {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  @include mobile-xl {
    justify-content: center;
  }

  .text_btns {
    line-height: 40px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    @include mobile-xl {
      margin-bottom: 12px;
    }
  }

  .delete_account_btn,
  .change_passw_btn {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.5;
  }
  .delete_account_btn {
    color: $gray;
    font-family: $font-family-gothic;
  }

  .save_changes_btn {
    min-width: 200px;
    @include mobile-xl {
      width: 100%;
    }
  }

  .spinner {
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    width: auto;
    transform: translate(-50%, -50%);
  }
}

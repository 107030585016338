@import 'src/theme.scss';

.container {
  padding-bottom: 30px;
  .action_btn {
    text-transform: unset;
    font-size: $text-size-md;
    font-weight: normal;
  }

  .delete {
    color: $gray;
  }

  .no_data {
    color: $gray;
    font-size: $text-size-xl;
    padding-top: 10px;
    padding-bottom: 16px;
    text-align: center;

    .place_order_link {
      font-weight: bold;
      text-transform: unset;
      font-size: $text-size-xl;
      top: -1px;
      padding: 0;
    }
  }
  .backdrop {
    z-index: 100;
  }
}

@import 'src/theme.scss';
.drawer_panel_wrapper {
  width: 100%;
  @include laptop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .counter_wrapper {
    display: flex;
    line-height: 0px;
    border-radius: 50%;
    color: $white;
    background-color: $primary;
    .bag_counter {
      display: inline-block;
      font-weight: 500;
      font-size: $text-size-md;
      padding-top: 50%;
      padding-bottom: 50%;
      margin-left: 7px;
      margin-right: 7px;
      &.mac_platform {
        padding-top: calc(50% + 1px);
        padding-bottom: calc(50% - 1px);
      }
    }
  }
  .MuiButtonBase-root.btn_primary.drawer_panel_btn_bag {
    border-color: $white;
    min-width: unset;
    display: flex;
    align-items: stretch;
    justify-content: center;
    .MuiButton-label.btn_primary_label {
      display: inline-flex;
      min-height: 100%;
      justify-content: center;
      align-items: center;
    }
    .drawer_panel_btn_bag_text {
      line-height: 1em;
      padding-bottom: 2px;
      &.mac_platform {
        padding-top: 2px;
        padding-bottom: 0;
      }
      .MuiButton-endIcon.btn_primary_endIcon {
        display: unset;
      }
    }
    @include laptop {
      padding: 12px 16px;
      border-color: $white;
    }
    @include mobile-xl {
      padding: 8px;
      min-width: 90px;
      height: 54px;
      .MuiButton-label.btn_primary_label {
        display: flex;
        align-items: center;
        justify-self: center;
        flex-direction: column;
        .MuiButton-endIcon.btn_primary_endIcon {
          margin: 0;
        }
      }
    }
    @include mobile {
      padding: 0;
      width: 40px;
      height: 40px;
      min-height: unset;
      .MuiButton-label.btn_primary_label {
        display: flex;
        align-items: center;
        justify-self: center;
        flex-direction: column;
        .MuiButton-endIcon.btn_primary_endIcon {
          margin: 0;
        }
      }
    }
  }
}

@import 'src/theme.scss';

.slider_description {
  color: $black;
  padding-top: 10px;
  font-size: $text-size-lg;
}

.slider_details_container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  .slider_details {
    color: $black-1;
  }

  .view_all {
    padding: 0;
    width: auto;
    margin-left: 10px;
    font-size: $text-size;
    text-transform: capitalize;
  }
}

.product_slider_item {
  width: 264px;
}

@import 'src/mixins';
@import 'src/theme';

.product_card_wrapper {
  position: relative;
  height: 100%;

  @include tablet {
    &:not(.horizontal_view) {
      margin-right: 16px;
    }
  }

  .plus_button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $primary;
    border: 2px solid $white;
    border-radius: 50%;
    font-size: $header-size-xl;
    line-height: 14px;
    color: $white;

    &.disabled {
      background-color: $gray-2;
      color: $gray;
      border-color: $gray;
      cursor: default;
    }
  }

  .product_card {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background: $white;
    height: 100%;

    &:hover {
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: 0.5s;

      .product_details {
        .name {
          color: $primary;
        }
      }
    }

    .product_image {
      height: 250px;
    }

    .product_details {
      padding: 24px;

      .name {
        @include block-ellipsis(none, 2);
        font-size: $header-size-xl;
        line-height: 1.15;
      }

      .cost_calories_wrapper {
        margin-top: 5px;
        color: $gray;
        font-family: $font-family-gothic;
        font-size: $text-size-lg;
      }

      .description {
        @include block-ellipsis(none, 3);
        color: $gray;
        font-size: $text-size-md;
        margin: 16px 0 0;
      }
    }
  }

  &.horizontal_view {
    .plus_button {
      top: 205px;
      left: 36px;
    }

    .product_card {
      display: flex;
      padding: 24px;

      .product_image {
        flex: 1;
        max-width: 400px;
      }

      .product_details {
        flex: 1;
        padding: 0 0 0 24px;

        .name {
          @include clear-block-ellipsis();
        }

        .description {
          @include clear-block-ellipsis();
        }
      }
    }
  }
}

@import 'theme.scss';
@import-normalize;

body,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  font-size: $text-size;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
p {
  text-align: left;
}
h1,
h2,
h3,
h4 {
  font-weight: 700;
  text-transform: uppercase;
  color: $black;
  margin: 0;
  padding: 0;
  font-family: $font-family-gothic;
}
h1 {
  font-size: $header-size-extra;
  line-height: 56px;
  @include mobile-xl {
    font-size: 32px;
  }
}
h2 {
  font-size: $header-size-xl;
  line-height: 33px;
}
h3 {
  font-size: $header-size-lg;
  line-height: 28px;
}
h4 {
  font-size: $header-size-md;
  line-height: 20px;
}
p {
  font-weight: normal;
  font-style: normal;
  color: $white;
}
a {
  color: $gray;
  text-decoration: none;
  font-size: $text-size-md;
  &:hover {
    text-decoration: underline;
  }
}
.uppercase {
  text-transform: uppercase;
}
.btn_link {
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  border: 0;
  padding: 0;
  background-color: inherit;
  color: inherit;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

input[type='text']::-ms-clear,
input[type='tel']::-ms-clear,
input[type='search']::-ms-clear {
  display: none;
}
input[type='password']::-ms-reveal {
  display: none;
}

@media print {
  html,
  body {
    height: 100%;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
  }

  body * {
    visibility: hidden;
  }

  body :not(#section-to-print, #section-to-print *) {
    margin: 0 !important;
    padding: 0 !important;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

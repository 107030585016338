@import 'src/theme.scss';
@import 'src/mixins.scss';

.available_rewards_list_conatiner {
  .MuiFormControlLabel-root.form_choose_btn_root {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .form_choose_btn_label {
      .choose_btn_label_wrapper {
        .choose_btn_info_section {
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          .choose_btn_label {
            color: $black-1;
            font-size: $text-size-sm;
            margin: 0;

            &_applied {
              color: $primary;
            }
          }

          .choose_btn_description_wrapper {
            font-size: $text-size-md;
            font-family: unset;
            align-self: flex-start;
            color: $gray;
            padding: 0;
          }
        }
      }
    }
  }
}

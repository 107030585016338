@import 'src/theme.scss';

.captcha {
  margin-bottom: 10px;
}
.signin_content {
  display: flex;
  flex-direction: column;
  height: inherit;

  .form_error_container {
    margin-bottom: 15px;
  }

  .content_form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: stretch;

    .form_input-email {
      margin-bottom: 10px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    .input_wrapper {
      width: 100%;

      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }

    .form_input-password {
      margin-bottom: 24px;
    }

    .form_button-signin {
      position: relative;

      button {
        height: 64px;
        width: 100%;
      }

      .button_spinner {
        position: absolute;
        margin-top: 3px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;

        svg {
          color: $white;
        }
      }
    }
  }

  .content_other-login-form {
    display: flex;
    flex-direction: column;
    flex: 1 50%;
    padding-top: 40px;

    @include mobile {
      padding-top: 26px;
    }

    .other-login-form_caption {
      display: flex;
      position: relative;
      justify-content: center;

      h4::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        height: 1px;
        width: 100px;
        background: $gray-2;
        left: 87%;
        z-index: 1;
        transform: translateX(-50%);

        @include mobile {
          width: 70px;
          left: 93%;
        }
      }

      h4::after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        height: 1px;
        width: 100px;
        background: $gray-2;
        left: 13%;
        z-index: 1;
        transform: translateX(-50%);

        @include mobile {
          width: 70px;
          left: 8%;
        }
      }
    }

    .other-login-form_button {
      flex-grow: 1;
      align-self: center;
      padding-top: 26px;

      @include mobile {
        padding-top: 16px;
      }

      span:active {
        opacity: 1;
      }

      .facebook {
        background-color: $facebook-color;

        &:hover {
          background-color: $facebook-color-hover;
          box-shadow: none;
        }

        &:active {
          background-color: $facebook-color-hover;
          box-shadow: none;
        }
      }
    }
  }

  .other-login-form {
    text-align: center;
    font-size: $text-size-lg;

    &.with_forgot_password {
      display: flex;
      justify-content: space-between;
    }

    .router_link {
      margin: 0;
    }

    button {
      padding: 0;
    }

    span {
      font-weight: bold;
    }
  }
}

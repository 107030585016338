@import 'src/theme.scss';

.captcha {
  margin-top: 8px;
}

.tooltip {
  color: $gray;
  font-size: $text-size-sm;
  margin-bottom: 8px;
  padding-top: 10px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include tablet-portrait {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include tablet-portrait {
    flex-direction: column;
  }
}
.absolute_error{
  .MuiFormLabel-root.MuiFormLabel-colorSecondary{
    font-size: 11px !important;
  }
}
.inputs_group {
  display: flex;
  align-items: center;
  flex: auto;

  .card_pin_input {
    flex: 1;
  }
  .absolute_error{
    .MuiFormLabel-root.MuiFormLabel-colorSecondary{
      font-size: 11px !important;
    }
  }
  @include tablet-portrait {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 0;

    .card_pin_input,
    .button_container {
      flex: 1;
    }
  }
}

.button_container {
  flex: 0 150px;
  width: 100%;
  min-width: 150px;
  margin-left: 8px;
  display: flex;
  gap: 5px;
  .button {
    height: 64px !important;
  }

  @include tablet-portrait {
    max-width: 100%;
    flex: 1 100%;
  }

  @include mobile-xl {
    min-width: 165px;
  }

  @include mobile {
    min-width: 100px;
  }

  .check_icon {
    font-size: 16px;
    margin-left: 8px;
  }
}
.row{
  padding-top: 20px;
}
.container_btn_plus{
  display: grid;
  padding-top: 20px;
  place-items: center;
}
.gift_card_input {
  padding-right: 8px;
  flex-basis: 50%;
  @include tablet-portrait {
    margin: 0 0 8px 0;
    padding-right: 0;
    width: 100%;
  }
}

.loading {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 20px);
}

.checkbox_wrapper {
  flex: 1 100%;
  margin-top: 24px;
  margin-bottom: 8px;
}

@include tablet-portrait {
  .checkbox_wrapper {
    margin-top: 16px;
  }

  .checkbox_wrapper_offset {
    padding-top: 30px;
  }
}
 
.input_container {
  height: 64px;

  @include tablet-portrait {
    height: unset;
  }
}
.plus_button {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $primary;
  border: 2px solid $white;
  line-height: 14px;
  color: $white;
  font-family: "New Gothic", "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: 1em;
  text-transform: uppercase;
  padding: 2rem;
  border-radius: 5px; 
  cursor: pointer;
  @include mobile {
    position: initial;
    right: -45%;
  }
  &.disabled {
    background-color: $gray-2;
    color: $gray;
    border-color: $gray;
    cursor: default;
  }
}




@import 'src/theme.scss';

.form_error_container {
  margin-bottom: 15px;
}

.form {
  text-align: center;
  max-width: 366px;
  margin: 0 auto;
  width: 100%;

  .form_input-email > div {
    margin-bottom: 16px;
  }

  .form_input-email div,
  .form_button-submit button,
  .form_signin button {
    width: 100%;
  }

  .form_button-submit button,
  .form_signin button {
    height: 64px;
  }

  .form_button-submit {
    position: relative;

    .button_spinner {
      position: absolute;
      margin-top: 3px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;

      svg {
        color: $white;
      }
    }
  }

  .form_signup {
    text-align: center;
    font-size: $text-size-extra;
    font-family: $font-family-gothic;
    font-weight: bold;
    padding-top: 24px;
  }

  .other-login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $text-size-lg;
    font-weight: bold;

    .router_link {
      margin: 0;

      button {
        font-size: $text-size-lg;
      }
    }
  }

  .form_success-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 80px;
      height: 80px;
      color: $green;
    }

    p.content_text {
      text-align: center;
      font-size: $text-size-xl;
      margin: 16px 0 40px;
      color: $gray;
      width: 65%;
    }
  }

  .form_signin {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    a {
      width: 100%;
      text-decoration: none;
    }
  }

  .form_resend-email button {
    font-family: $font-family-gothic;
  }
}

.form_button-forgot-password {
  display: inline-flex;
  justify-content: center;

  .router_link {
    display: inline-flex;

    button {
      font-size: $text-size-md;
      padding: 0;
    }
  }
}

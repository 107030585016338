@import 'src/theme.scss';

.quantity_container {
  display: flex;

  .left_btn {
    border-radius: 4px 0 0 4px;
  }

  .right_btn {
    border-radius: 0 4px 4px 0;
  }

  .left_btn,
  .right_btn {
    &.revert {
      color: $primary;
      background-color: $white;
      border-color: $white;
    }
  }

  .value_wrapper {
    display: flex;
    align-items: center;
    background-color: $primary-light;

    .value {
      color: $primary;
      font-family: $font-family-gothic;

      &.medium {
        padding: 0 28px;
        font-size: $text-size-extra;
      }

      &.small {
        padding: 0 13px;
        font-size: $text-size-lg;
      }
    }
  }
}

@import 'src/theme.scss';

.container {
  padding: 24px 0 48px;

  & > div, & > button {
    width: calc(33.3% - 4px);

    @include laptop {
      width: calc(50% - 4px);
    }

    @include mobile-xl {
      width: 100%;
    }
  }

  .wrapper {
    display: none;
    margin-top: 16px;

    &.visible {
      display: flex;
      flex-direction: column;
    }

    .input:first-child {
      margin-bottom: 8px;
    }
  }
}

@import 'src/theme.scss';
.wrapper {
  button.button {
    padding: 0;
    text-transform: unset;
    text-align: right;
    font-size: $text-size-md;
    line-height: 18px;
  }
}
.spinner {
  padding: 0;
}

@import 'src/theme.scss';
@import 'src/mixins.scss';

.container {
  h3,
  h4 {
    text-transform: unset;
    font-weight: 600;
  }

  .change_btn {
    text-transform: unset;
  }

  .edit_btn,
  .change_btn {
    padding-right: 0;
  }

  .account_info_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    h4 {
      text-transform: uppercase;
    }
  }

  .account_item_label {
    width: 40%;
    font-family: $font-family;
    text-transform: uppercase;
    font-size: $text-size-md;
    font-weight: 900;
  }

  .account_item_name {
    font-size: $header-size-lg + 6px;
    text-transform: uppercase;
    @include block-ellipsis(100%, 1);
    margin: 0;
  }

  .account_item_value {
    font-size: $text-size-md;
    font-weight: 400;
    @include block-ellipsis(18px, 1);
    color: $gray;
    width: 60%;
    text-align: right;
    overflow-wrap: break-word;
  }

  .center {
    margin: 0 auto;
    .print_btn {
      font-family: $font-family-gothic;
      font-size: $text-size-lg;
    }
  }

  .communication_pref_btn {
    text-transform: unset;
    font-weight: normal;
  }
}

@import 'src/theme.scss';

.modal_content {
  max-height: 400px;
  overflow: auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;

  @include mobile-xl {
    padding: 0;
    margin-bottom: 16px;
  }

  .change_password_form {
    display: flex;
    flex-direction: column;
    width: 370px;
    margin-bottom: 26px;

    @include mobile-xl {
      margin: 0;
    }

    .input_container {
      margin: 4px 0;
    }
    p.help_message {
      padding: 5px 2px 7px;
      color: $black;
    }
  }
}

.modal_footer {
  display: flex;
  justify-content: center;
  width: 100%;

  .btn {
    min-width: 170px;
    @include mobile-xl {
      width: 100%;
    }
  }

  .spinner {
    position: absolute;
    margin-top: 6px;
    padding: 0;
  }
}

@import 'src/theme.scss';

.container .button {
  font-size: $text-size-md;
  text-transform: capitalize;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  min-width: auto;

  @include mobile-xl {
    padding: 0;
  }
}

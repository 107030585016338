@import "src/theme.scss";
.router_link {
  color: $black;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  & > button {
    padding: 0 10px 5px;
    line-height: 1;
  }
}
.extend_link.isGothic,
.router_link.isGothic,
.router_link.isGothic button span {
  font-family: $font-family-gothic;
  font-size: $text-size-extra;
}
.extend_link.uppercase,
.router_link.uppercase {
  text-transform: uppercase;
}
.extend_link.primary,
.router_link.primary {
  color: $primary;
}
.extend_link.underline,
.router_link.underline {
  text-decoration: underline;
}
.extend_link {
  &:hover {
    cursor: pointer;
  }
}

@import 'src/theme.scss';
$margin-from-marker: 100px;
.wrapper {
  position: relative;
  font-family: $font-family;
}
.info_window {
  position: absolute;
  z-index: 100;
  left: calc(0px - #{$map-info-box-width} / 2);
  bottom: calc(-100% + #{$margin-from-marker});
}

@import 'src/theme.scss';

.modal_header_actions {
  .arrow_icon {
    font-size: 18px;
    margin-right: 5px;
  }

  @include mobile-xl {
    .arrow_label {
      display: none;
    }
  }
}

.modal_content {
  overflow: auto;
  padding: 0 20px;
  @include mobile-xl {
    padding: 0;
  }
}

.modal_footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 2rem;

  @include mobile-xl {
    padding: 0;
  }

  .order_btn {
    width: 50%;
    min-width: 200px;
    @include mobile-xl {
      width: 100%;
    }
  }

  .spinner {
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@import 'src/theme.scss';

.container {
  background-color: $white;
  border-radius: 8px;
  padding: 24px;
  .logo {
    display: inline-block;
    height: 18px;
  }
  .title {
    color: $black;
    text-align: center;
    margin-bottom: 4px;
    text-transform: none;
    font-family: $font-family;
    font-size: $text-size-xl;
    span {
      img {
        margin-right: 2px;
      }
    }
    @include mobile-xl {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  }
}
.description {
  font-size: $text-size-md;
  text-align: center;
  color: $gray;
}
.btn_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 16px;
  .send_refer_btn {
    font-size: $text-size-lg;
    font-weight: 600;
  }

  .view_refer_history_btn {
    text-transform: uppercase;
    font-size: $text-size-xl;
    font-weight: 700;
    font-family: $font-family-gothic;
  }
}

@import 'src/theme.scss';
.modal_content {
  padding: 0 14px;

  h3 {
    text-transform: none;
    font-size: $text-size-xl;
    font-family: $font-family;
  }
}
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

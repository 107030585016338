@import "src/theme.scss";

.image_container {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .no_picture {
    display: inline-block;
    background: $gray-2;
    height: 100%;
    width: 100%;
  }
}

.no_image_wrapper {
  background-color: $gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .no_image_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no_image_circle {
      border-radius: 50%;
      background: $white;
      width: 80px;
      height: 80px;
      position: relative;

      .no_image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .no_image_description {
      color: $gray;
      font-size: $text-size-md;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 16px;
    }
  }
}

@import 'src/theme.scss';
.wrapper {
  padding: 60px 20%;
  background-color: $gray-3;
  flex: 1;
  @include large-desktop {
    padding-left: 25%;
    padding-right: 25%;
  }
  @include laptop {
    padding-left: 15%;
    padding-right: 15%;
  }
  @include tablet {
    padding-left: 48px;
    padding-right: 48px;
  }
  @include tablet-portrait {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include mobile-xl {
    padding: 0;
    background-color: $gray-3;
  }
}
.container {
  background-color: $white;
  padding: 56px 120px 32px;
  height: 100%;
  flex: 1;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  .header_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    .header {
      .title {
        text-align: center;
        font-size: $header-size-extra - 8px;
        line-height: 40px;
        @include mobile-xl {
          font-size: $header-size-xl - 2px;
          line-height: normal;
        }
      }
    }
  }
  @include laptop {
    padding: 52px 90px 30px;
  }
  @include tablet {
    padding: 30px 60px 30px;
  }
  @include mobile-xl {
    padding: 25px 25px 36px;
  }
}
.order_row {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  div {
    flex: 1 50%;
    &:first-child {
      margin-right: 30px;
    }
  }
  @include mobile-xl {
    flex-direction: column;
    div {
      flex: 1 100%;
      &:first-child {
        margin-right: 0;
      }
    }
  }
}

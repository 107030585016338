@import 'src/theme.scss';

.content {
  margin: 0 auto 64px;
  max-width: 560px;

  .title {
    color: $black-1;
    word-break: break-word;
  }

  .instructions_wrapper {
    margin-top: 25px;
    color: $gray;
    font-size: $text-size-xl;
    line-height: 22px;

    .list {
      .list_item {
        &:before {
          content: '-';
          display: inline-block;
          margin-right: 7px;
        }

        b {
          font-weight: 600;
        }
      }
    }
  }

  .description {
    margin-top: 25px;
    font-size: $text-size-xl;
    line-height: 22px;
    color: $gray;
  }

  @include mobile-xl {
    margin-top: 20px;

    .title {
      font-size: $header-size-lg;
    }
  }
}

@import 'src/theme';

$menu_item_gap: 10px;

.section_description {
  color: $black;
  font-size: $text-size-xl;
}

.products {
  margin: 0 -$menu_item_gap;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  @include tablet-portrait {
    .product {
      width: 50%;
    }
  }

  @include mobile-xl {
    align-content: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .product {
    padding: $menu_item_gap;
    width: 25%;

    @include tablet {
      width: 33%;
    }
    @include tablet-portrait {
      width: 50%;
    }
    @include mobile-xl {
      width: auto;
    }
  }
}

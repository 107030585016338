@import 'src/theme.scss';
.modal {
  position: relative;
  .title.lg {
    width: min-content;
  }
}
.modal_content {
  padding: 20px 30px;
  max-width: 600px;
  margin: 0 auto;

  @include tablet {
    padding: 0 45px;
  }
  @include mobile-xl {
    padding: 16px 0;
  }
}
.checkbox_wrapper {
  margin-bottom: 24px;
}
.modal_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .spinner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: unset;
    padding: 0;
  }
  .btn {
    position: relative;
    min-width: 300px;
    @include mobile-xl {
      width: 100%;
      min-width: auto;
    }
  }
}
.loading_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}

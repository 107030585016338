@import 'src/theme.scss';
.MuiFormControl-root.form_select_root {
  width: 100%;
  height: 100%;
}
.MuiFormControl-root.form_select_root .MuiSelect-root.select_form.MuiSelect-filled.MuiFilledInput-input {
  height: 100%;
  box-sizing: border-box;
}
.MuiFormControl-root.form_select_root .MuiFormLabel-root .Mui-focused {
  color: $gray;
  background: none;
}
.MuiFormControl-root.form_select_root .MuiFormLabel-root.Mui-focused {
  color: $gray;
}
.MuiSelect-root.MuiSelect-select {
  border: 1px solid $gray-2;
  border-radius: 4px;
  &:focus {
    background-color: $white;
    border-radius: 4px;
  }
}
.Mui-error .MuiSelect-root.MuiSelect-select {
  border-color: $error-color;
}
.MuiFormControl-root.form_select_root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  border-color: $gray-2;
  font-size: $text-size-md;
  font-family: $font-family;
  &:hover {
    border-color: $gray;
  }
}
.MuiFormControl-root.form_select_root .MuiInputBase-formControl {
  &:hover {
    border-color: $gray;
  }
}
.MuiFilledInput-root {
  background-color: $white;
}
.MuiFormControl-root.form_select_root .MuiSelect-root.select_form.MuiSelect-outlined.MuiOutlinedInput-input {
  background-color: $white;
}
.MuiFormControl-root.form_select_root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $gray;
  background: none;
  color: $black;
}
.MuiFormControl-root.form_select_root .MuiFilledInput-root.Mui-focused .MuiFilledInput-notchedOutline {
  border: 1px solid $gray;
  background: none;
  color: $black;
}
.MuiFormControl-root.form_select_root .form_select_label_disabled {
  color: $gray;
}
.MuiFormControl-root.form_select_root .MuiPopover-paper {
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  min-width: 50px;
  max-width: 200px;
  border: 1px solid $gray;
}
.MuiFormControl-root.form_select_root .MuiList-root.MuiMenu-list {
  padding: 0;
}
.MuiFormControl-root.form_select_root .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: $gray-2;
}
.MuiFormControl-root.form_select_root .MuiFilledInput-root.Mui-disabled .MuiFilledInput-notchedOutline {
  border-color: $gray-2;
}
.MuiFormControl-root.form_select_root .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl.Mui-error {
  border-color: $error-color;
}
.MuiFormControl-root.form_select_root .select_form_disabled {
  color: $gray-2;
  background-color: $gray-3;
}

.MuiFormControl-root.form_select_root .disable_white .select_form_disabled {
  background-color: $white;
}

.MuiFormControl-root.form_select_root .select_item {
  border-bottom: 1px solid $gray-2;
  min-height: 57px;
  &:last-child {
    border-bottom: none;
  }
}

@import 'src/theme.scss';
@import 'src/mixins.scss';

.available_rewards_list_container {
  .available_rewards_list_item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    .rewards_info {
      min-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .reward_name {
      color: $white;
      font-weight: 700;
      @include block-ellipsis(27px, 1);
      margin: 0;
    }
    .reward_expiration {
      color: $primary-light;
      font-size: $text-size-md;
      line-height: 24px;
    }
    .add_to_order_btn {
      color: $white;
      font-size: $text-size-xl + 2px;
      letter-spacing: normal;
      line-height: 24px;
      font-weight: 700;
      font-family: $font-family-gothic;
      padding: 0;
      min-width: unset;
    }
    @include mobile-xl {
      flex-direction: column;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $primary-light;
    opacity: 0.2;
  }
}

@import "src/theme.scss";

.logo_link {
  & > img {
    width: 100%;
    max-width: 125px;
  }

  &:hover {
    cursor: pointer;
  }
}

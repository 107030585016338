@import 'src/theme.scss';
.modal_content {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 135px auto;
    max-width: 486px;
    p {
      font-size: $text-size-xl;
      line-height: 1.5em;
      color: $black;
      margin: 24px 0;
      text-align: center;
    }

    .button {
      width: 100%;
      max-width: 486px;
    }

  }
}

@import 'src/theme.scss';

.as-guest_content {
  display: flex;
  flex-direction: column;
  height: inherit;

  .content_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: inherit;

    div > div,
    .form_as-guest-button > button {
      width: 100%;
    }

    .form_first-name,
    .form_last-name,
    .form_email,
    .form_phone {
      margin-bottom: 10px;
    }

    .form_as-guest-button {
      position: relative;
      margin-top: 20px;

      button {
        height: 60px;
      }

      .button_spinner {
        position: absolute;
        margin-top: 3px;
        svg {
          color: $white;
        }
      }
    }
  }
}

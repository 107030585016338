@import 'src/theme.scss';

$container-padding-horizontal: 25px;

.wrapper {
  padding: 120px 20%;
  background-color: $gray-1;
  flex: 1;

  .container {
    background-color: $white;
    padding-top: 50px;
    height: 100%;
    flex: 1;
    border-radius: 4px;
    max-width: 920px;
    margin: 0 auto;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

    .form_header {
      padding: 0 $container-padding-horizontal 40px;
      text-align: center;
    }
  }

  @include laptop {
    padding: 60px 10%;
  }
  @include tablet {
    padding-left: 48px;
    padding-right: 48px;
  }
  @include tablet-portrait {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include mobile-xl {
    padding: 0;

    .container {
      border-radius: 0;
    }
  }
}

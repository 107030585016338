@import 'src/theme.scss';

.order_time_wrap {
  position: relative;
  .alert {
    margin-bottom: 10px;
  }
  &__mobile {
    padding-bottom: 10px;
  }
  .order_time {
    &__loading {
      opacity: 0.5;
    }
    font-size: $font-family;
    .time_buttons_wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .asap_button {
      flex: 1;
      margin-right: 10px;
    }
    .scheduled_button {
      flex: 2;
    }
    .date_time_wrap {
      display: flex;
      align-items: center;
      //margin-top: -40px;
      // transform: translateY(-40px);
      .time,
      .date {
        height: 76px;
      }
      .time {
        background-color: transparent;
        border: none;
      }
      .time_select {
        padding: 29px 12px 10px;
      }
      .date {
        width: 85%;
        margin-right: 6px;
        border-radius: 4px;
      }
      .time_title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 16px;
        color: $gray;
        @media only screen and (max-width: 600px) {
          font-size: 0.58rem;
          padding-left: 6px;
        }
      }
      .date_label,
      .time_label {
        transform: translate(12px, 31px) scale(1);
        color: $gray;
        font-size: 16px;
      }
      .date_input {
        height: 76px;
        input {
          padding: 27px 12px 24px;
        }
      }
    }
    @media (min-width: 768px){
      .date_time_wrap{
        margin-top: -40px;
      }
    }
    @media (min-width: 768px){
      .date_time_wrap{
        margin-top: -70px;
      }
    }
    .order_type_button_label {
      font-size: 18px;
      text-transform: capitalize;
    }
  }
  .time_mode_btn div p {
    font-size: $text-size-xl;
  }
  .spinner {
    position: absolute;
    padding: 55px 0;
  }
}
.helperText {
  position: absolute;
  top: 76px;
}
.info_wrapper {
  display: flex;
  padding: 0 0 15px;
  svg {
    color: $warning-color;
  }
}
.info {
  font-size: $text-size-md;
  margin-left: 8px;
  padding-top: 5px;
  color: $dark-gray;
}

@import 'src/theme.scss';
.wrapper {
  padding: 60px 20%;
  background-color: $gray-3;
  flex: 1;
  @include laptop {
    padding-left: 10%;
    padding-right: 10%;
  }
  @include tablet {
    padding-left: 48px;
    padding-right: 48px;
  }
  @include tablet-portrait {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include mobile-xl {
    padding: 0;
  }
}
.container {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}
.body {
  background-color: $white;
  padding: 40px 120px 20px;
  flex: 1;
  padding-bottom: 32px;
  @include laptop {
    padding: 40px 90px 30px;
  }
  @include tablet {
    padding: 30px 60px 30px;
  }
  @include mobile-xl {
    padding: 25px 25px 0;
  }

  .input_container {
    width: 100%;
    margin-bottom: 8px;
  }
}
.footer {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-3;
  @include mobile-xl {
    padding: 0 25px 0;
  }

  .save_btn {
    width: 445px;
    position: relative;
    .spinner {
      position: absolute;
    }
  }
}

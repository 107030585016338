@import 'src/theme.scss';

.header_wrapper {
  display: inline-block;
  position: relative;

  .brush_before {
    position: absolute;
    height: 120%;
    width: 100%;
    max-width: 400px;
    top: -10%;
    left: 0;
    background-image: url('../../assets/brush.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateX(-35%);
    z-index: 0;
  }

  .title_wrapper {
    position: relative;
    display: inline-block;
    padding: 8px;
    background-color: $primary;
    //border-radius: 35%;
    z-index: 1;

    // &:not(.before) {
    //   border-radius: 0 35% 35% 0;
    // }

    // &:not(.after) {
    //   border-radius: 35% 0 0 35%;
    // }

    .title {
      color: $white;
      font-size: $header-size-md;
      font-family: $font-family-gothic;
      text-transform: uppercase;
      &.sm {
        font-size: $header_size-sm;
      }
      &.md {
        font-size: $header_size-md;
      }
      &.lg {
        font-size: $header-size-xl;
        line-height: 24px;
      }
      &.xl {
        font-size: $header-size-xl;
        line-height: 32px;
      }
      &.extra {
        font-size: $header_size-extra;
        line-height: 56px;

        &.extra-line-height {
          line-height: 56px;
        }
      }
    }

    @include mobile-xl {
      padding: 8px;
    }
  }

  .brush_after {
    position: absolute;
    height: 120%;
    width: 100%;
    max-width: 400px;
    top: -10%;
    left: 100%;
    background-image: url('../../assets/brush.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateX(-70%);
    z-index: 0;
  }

  &.revert {
    .brush_before,
    .brush_after {
      background-image: url('../../assets/brush_revert.svg');
    }

    .title_wrapper {
      background-color: $white;

      .title {
        color: $primary;
      }
    }
  }

  @include mobile-xl {
    max-width: 70%;
  }
}

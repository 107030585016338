@import 'src/theme.scss';

.wrapper {
  border-radius: 8px;
  padding: 0;
  text-align: center;
  font-size: $text-size-md;
  color: $black;
  h3 {
    font-family: $font-family;
    font-size: $text-size-xl;
    line-height: 26px;
    text-transform: unset;
    margin-bottom: 8px;
    text-align: left;
  }
  p {
    text-align: left;
    color: $gray;
  }

  .link {
    padding: 0;
    text-transform: unset;
    font-size: $text-size-md;
    text-decoration: underline;
    font-weight: normal;
    line-height: unset;
    margin-bottom: 3px;
    font-weight: normal;
    font-style: normal;
  }
}

@import 'src/theme.scss';
.MuiAccordion-root.Mui-expanded.accordion_panel {
  margin: 0;
}
.MuiAccordion-root.accordion_panel {
  border-radius: unset;
  box-shadow: none;
  border-bottom: 1px solid $gray-2;
  background-color: inherit;
  & .accordion_header.MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px;
    }
  & .accordion_header {
    font-size: $header-size-lg - 2px;
    font-weight: 600;
    line-height: 33px;
    padding: 0;
    color: $black-1;
    .MuiAccordionSummary-content {
      margin: 16px 0;
    }
  }
  & .accordion_header_icon {
    color: $black-1;
    .MuiIconButton-label svg {
      font-size: 2rem;
    }
  }
  & .accordion_details {
    flex-direction: column;
    padding: 0;
  }
}

@import 'src/theme.scss';
.content_wrapper {
  height: auto;
  padding: 40px 48px;
  background-color: $gray-3;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include laptop {
  }
  @include tablet-portrait {
    padding: 24px;
  }
  @include mobile-xl {
    overflow: auto;
  }
  @include mobile {
    background-image: none;
    padding: 0;
  }
}
.order {
  width: 100%;
  max-width: 925px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;
  @include tablet {
    max-width: 690px;
  }
  @include mobile-xl {
    max-width: 345px;
  }
  @include mobile {
    max-width: none;
    border-radius: unset;
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  position: relative;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
  .backdrop {
    width: 100%;
    height: 100%;
    background-color: $gray-3;
    opacity: 0.7;
    left: 0;
    top: 0;
    padding: 10px;
    display: block;
    position: absolute;
    z-index: 100;
  }
}


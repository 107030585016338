@import 'src/theme.scss';

.visits_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  margin-top: 16px;
  background-color: rgba(132, 23, 43, 1);
  border-radius: 4px;

  .visits_title {
    color: #fff;
  }

  .visits_subtitle {
    color: #fff;
    margin-bottom: 12px;
  }

  .visits_stepper {
    display: flex;
    align-items: center;
  }

  .step_wrapper,
  .step_wrapper_active {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff50;
    border-radius: 50%;

    img {
      width: 32px;
      height: 32px;
      filter: grayscale(1);
    }
  }

  .step_wrapper_active {
    background-color: #fff;
    border: 2px solid $warning;
    box-sizing: border-box;

    img {
      width: 34px;
      height: 34px;
      filter: grayscale(0);
    }
  }

  .separator {
    width: 40px;
    height: 0px;
    border: 2px solid #ffffff50;
  }
}

@import 'src/theme.scss';
.MuiButtonBase-root.order_type_link {
  text-transform: capitalize;
  color: $white;
  font-size: $text-size-md;
  line-height: unset;
  margin: 0;
  padding: 0;
  font-weight: 600;
  min-width: auto;
  &:hover {
    color: $gray-1;
  }
}
.order_type_link_wrap {
  font-size: $text-size-md;
}

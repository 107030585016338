@import 'src/theme.scss';

.choose_favorite_location_container {
  padding-bottom: 24px;
  .form {
    display: flex;
    margin: 0;
    max-width: unset;
    .input_container {
      flex-grow: 1;
    }
    .search_btn {
      height: 64px;
      margin-left: 10px;
    }
  }
  .locations_list {
    .locations_result_label {
      padding-top: 35px;
      padding-bottom: 15px;
      text-transform: unset;
      font-family: $font-family;
    }
    .MuiFormGroup-root {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .choosed_location {
      .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-weight: 400;
        color: $black;
      }
    }
    .distance {
      font-weight: 900;
    }
    .MuiFormGroup-root .MuiIconButton-label {
      border-radius: 50%;
      background-color: $white;
    }
  }
}

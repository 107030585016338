@import 'src/theme.scss';
.wrapper {
  color: $black;
  padding-top: $page-header-height;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: $gray-3;
  @include laptop {
    padding-top: $page-header-height-tablet;
  }
  @include tablet {
    padding-top: $page-header-height-tablet;
  }
  @include mobile {
    padding-top: $page-header-height-mobile;
  }
  @include mobile-xl {
    :global(.Toastify .Toastify__toast-container) {
      width: 90%;
    }
    :global(.Toastify__toast-container--top-right) {
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.content_wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

@import 'src/theme.scss';
.section {
  :global(.section_title) {
    white-space: nowrap;
  }
  .wrapper {
    h4 {
      font-family: $font-family;
      font-size: $header-size-md;
      line-height: 28px;
      font-weight: 800;
      margin-bottom: 8px;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }
}

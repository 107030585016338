@import 'src/theme.scss';

.order_summary_list {
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 18px 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .total {
    border-top: 2px solid $gray;
  }
  .name {
    font-size: $text-size-xl;
    line-height: 22px;
    font-weight: 400;
    @include mobile-xl {
      font-size: $text-size-lg;
      padding-right: 16px;
    }
    .choices {
      padding-left: 16px;
      padding-top: 8px;
      padding-bottom: 16px;
      .choice {
        font-size: $text-size-md;
        font-weight: 400;
        color: $gray;
      }
    }
  }

  .cost {
    font-size: $text-size-xl;
    font-weight: 700;
  }
  .accent {
    font-weight: 700;
    font-size: $text-size-xl;
  }
  .subtotal_label {
    font-weight: 700;
    font-size: $text-size-xl;
  }

  .estimated_total {
    text-transform: uppercase;
    font-size: $header-size-lg + 6px;
    @include mobile-xl {
      font-size: $header-size-lg;
    }
  }
  .estimated_subtotal {
    font-size: 20px;
  }
}

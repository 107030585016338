@import 'src/theme';

$menu_item_gap: 10px;

.container {
  padding-top: 14px;
}

.section {
  margin: 0;
}

.products {
  margin: -$menu_item_gap;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;

  @include tablet-portrait {
    .product {
      width: 50%;
    }
  }

  @include mobile-xl {
    align-content: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .product {
    padding: $menu_item_gap;
    width: 25%;

    @include tablet {
      width: 33%;
    }
    @include tablet-portrait {
      width: 50%;
    }
    @include mobile-xl {
      width: auto;
    }
  }
}

.no_search_results {
  max-width: 375px;
  text-align: center;
  margin: 120px auto;

  .icon {
    width: 100%;
  }

  p {
    text-align: center;
    font-size: $text-size-lg;
    line-height: 2em;
    padding: 28px 0 24px;
  }
}

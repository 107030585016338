@import 'src/theme.scss';
.wrapper {
  position: fixed;
  right: 40px;
  bottom: 25px;
  z-index: 10;
  @include tablet {
    display: none;
  }
}

@import 'src/theme.scss';
.container {
  margin-top: 0;
  margin-bottom: 40px;
  .title {
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: none;
    font-weight: normal;
  }
  .manage_button {
    padding-left: 0;
    padding-right: 0;
    font-size: $text-size-md;
    text-transform: capitalize;
    font-weight: normal;
    text-decoration: underline;
    min-width: auto;
  }
  @include mobile-xl {
    .manage_button {
      padding: 0;
    }
  }
  .recipient {
    color: $gray;
    font-size: $text-size-md;
  }
}
.deadline {
  letter-spacing: 0.01em;
  font-size: $text-size-md;
  color: $error-color;
  font-weight: bold;
  margin-bottom: 20px;
}

@import 'src/theme.scss';

button.submit_btn {
  @include mobile-xl {
    width: 100%;
    padding: 8px;
  }
}

.wrapper {
  .alert_wrapper {
    margin-bottom: 24px;
  }

  .section {
    margin: 0;
  }
}

.header .divider {
  margin: 16px 0;
}

.delivery_type {
  display: flex;
  align-items: center;
  color: $gray;
  font-size: $text-size-md;

  .edit_schedule {
    padding: 0;
    text-transform: unset;
    text-decoration: underline;
    font-size: $text-size-md;
    font-weight: 400;
    margin-left: 4px;
  }
}

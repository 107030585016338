@import 'src/theme.scss';

.menu_item {
  padding: 16px 0;
  border-bottom: 1px solid $gray-2;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  .item_header {
    display: flex;
    justify-content: space-between;

    .title_price_wrapper {
      display: flex;
      flex: 1;

      &:hover {
        .title,
        .price {
          color: $primary-hover;
          cursor: pointer;
        }
      }

      .title {
        flex: 1;
        font-family: $font-family;
        font-size: $text-size-xl;
        font-weight: 400;
        text-transform: unset;
        color: $black-1;
      }

      .price {
        font-size: $text-size-xl;
        text-align: right;
        font-weight: 800;
        margin: 0 12px;
      }
    }
  }

  .recipient {
    padding-top: 4px;
    color: $gray;
    font-size: $text-size-md;
    font-weight: 600;
  }

  .description {
    padding-top: 4px;
    color: $gray;
    font-size: $text-size-md;
    line-height: 18px;
  }
}

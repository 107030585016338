@import 'src/theme.scss';

.wrapper {
  text-align: center;

  @include mobile-xl {
    padding-bottom: 24px;
  }

  .modal_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;

    .text {
      text-transform: none;
      font-weight: normal;
      font-size: $text-size-xl;

      @include mobile-xl {
        font-size: $header-size-md;
        line-height: 1.6;
      }
    }

    .submit_btn {
      margin: 30px 0 0;
    }

    .reward_title {
      font-weight: 800;
    }
  }
}

@import 'src/theme.scss';

.account_container {
  display: flex;
  margin: 100px;

  .account_details_wrapper {
    width: 35%;
    margin-right: 20px;
    min-width: 400px;
  }

  .account_sections_wrapper {
    width: 65%;
  }

  .view_history_btn {
    text-transform: unset;
    font-size: $text-size-md;
    font-weight: normal;
  }

  .account_section_wrapper {
    padding: 10px 40px;
    background-color: $white;
    border-radius: 8px;

    .section_link {
      margin-right: 15px;
    }

    .quialified_history_description {
      display: block;
      color: $gray;
      font-size: $text-size-md;
      line-height: $text-size-xl;
    }

    @include mobile-xl {
      padding: 10px 15px;
    }
  }

  @include laptop {
    margin: 24px;
  }

  @include tablet {
    margin: 50px;
    flex-direction: column;

    .account_details_wrapper {
      width: 100%;
      margin: 0 0 24px;
      min-width: unset;
    }

    .account_sections_wrapper {
      width: 100%;
    }
  }

  @include tablet-portrait {
    margin: 24px;
  }

  @include mobile-xl {
    margin: 12px;
  }
}

@import 'src/theme.scss';
.wrapper {
  height: 100%;

  .restaurants {
    padding-bottom: 16px;
  }
  .restaurant {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;
    & > div {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.handheld_mode {
    width: 100%;

    .restaurants {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .restaurant {
      width: calc(50% - 14px);
      margin-bottom: 14px;
      align-items: flex-start;
      justify-content: flex-start;
      &:nth-child(2n - 1) {
        margin-right: 14px;
      }
      & > div {
        max-width: unset;
      }

      @include mobile-xl {
        flex: 1 100%;
        width: 100%;
        margin-bottom: 8px;
        margin-right: 0;
        justify-content: center;
        &:nth-child(2n - 1) {
          margin-right: 0;
        }
      }
    }
  }
}


@import 'src/theme.scss';
.modal_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal_msg {
  text-align: center;
  display: inline-block;
  margin-bottom: 16px;
  font-size: $text-size-xl;
  color: $black;
}
.spinner {
  position: absolute;
  margin-top: 4px;
  padding: 0;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    margin: 0 16px;
  }
}

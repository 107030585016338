@import 'src/theme.scss';
.order_place_card_container.order_place_card_container_map {
  width: $map-info-box-width;
  z-index: 100;
  &:hover {
    cursor: default;
  }
  .wrapper {
    &:hover {
      cursor: default;
    }
  }
}
.order_place_card_container {
  max-width: 345px;
  min-width: 250px;
  box-sizing: border-box;
  position: relative;

  &.view_mode {
    min-width: 305px;
  }

  &.handled_mode {
    max-width: unset;
  }

  @include mobile-xl {
    &.view_mode {
      min-width: 200px;
    }
  }

  &:hover:not(.view_mode) {
    cursor: pointer;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: $white;
    padding: 24px;
    z-index: 10;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid $gray-2;
    transition: 0.3s ease-in-out;

    &.view_mode {
      border: 1px solid $gray-2;
      border-radius: 4px;
    }

    &.handled_mode {
      border: none;
    }

    &:hover:not(.view_mode) {
      box-shadow: 3px 3px 15px -10px $gray;
      cursor: pointer;
    }
    & .radio_wrapper {
      margin-right: 16px;
      & .MuiRadio-colorPrimary {
        padding: 0;
      }
    }
    & .information {
      width: 100%;

      & .title {
        margin-bottom: 8px;
        line-height: 24px;
        font-size: 22px;
        font-family: $font-family-museo;
        font-weight: bold;
        text-transform: capitalize;
      }
      & .description {
        color: $gray;
        font-size: $text-size-md;
        line-height: 18px;
        text-align: left;
        & .get_deriction_header {
          line-height: 18px;
        }
        & .text_accent {
          color: $primary;
          font-size: $text-size-md;
          font-family: $font-family;
          font-weight: normal;
          text-transform: none;
          display: inline-block;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        .restaurant_info {
          display: flex;
          align-items: center;
          border-top: 1px solid $gray-1;
          padding: 8px 0;
          font-size: $text-size-md;
          
          & .working_hours,
          & .phone {
            font-size: $text-size-md;
            font-family: $font-family;
            font-weight: normal;
            color: $gray;
            text-transform: none;
            line-height: 18px;
          }

          &:last-child {
            padding-bottom: 0;
          }

          .icon {
            margin-right: 5px;
            height: 1.2em;
            width: 1.2em;
          }
        }
      }
    }
  }

  .order_place_card_active {
    border-radius: 4px 4px 0 0;
  }

  .button {
    position: relative;
    z-index: -1;
    font-family: $font-family-gothic;
    background-color: $primary;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 0px;
    line-height: 55px;
    font-weight: 600;
    font-size: $text-size-extra;
    transition: height 0.4s ease-out;
    border-radius: 0 0 4px 4px;

    &:hover {
      background-color: $primary-hover;
    }

    .icon {
      margin-left: 4px;
      font-size: $text-size-lg;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .btn_disabled {
    color: $gray-2;
  }
  .order_place_card_btn_map {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 95%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $primary;
    }

    &:hover::after {
      border-top: 20px solid $primary-hover;
    }
  }
  .order_place_card_btn_map.btn_disabled {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 95%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $black-1;
    }
  }
  .order_place_card_btn_active {
    height: 55px;
    z-index: 1;
    &.mac_platform .icon {
      padding-bottom: 3px;
      line-height: 1em;
    }
  }
  .order_place_card_active {
    outline: none;
    padding: 24px;
    position: relative;
    &:hover {
      box-shadow: none;
    }
  }
  .btn_disabled {
    background-color: $black-1;
    &:hover {
      cursor: default;
      background-color: $black-1;
    }

    @include tablet {
      &:not(.order_place_card_btn_map) {
        background-color: $gray;
      }
    }
  }
}

@import 'src/theme.scss';
@import 'src/mixins.scss';

$action-width: 140px;

.action_table {
  .name-title,
  .action-title {
    color: $gray;
    font-weight: 700;
    font-size: $text-size-lg;
    text-transform: uppercase;
  }

  .action-title {
    padding: 0 8px;
  }

  .action-table-header {
    display: flex;
    justify-content: space-between;
    font-family: $font-family-gothic;
    border-bottom: 1px solid $gray-2;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .left-column {
    width: calc(100% - #{$action-width});
    h4 {
      text-transform: unset;
      width: 100%;
      overflow-wrap: break-word;
      font-family: $font-family;
      font-size: $text-size-md;
    }

    .description {
      color: $gray;
      font-size: $text-size-sm;
      padding-top: 7px;
    }
  }

  .MuiDivider-root.divider_root {
    margin: 16px 0;
  }

  .action-table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .action-btn {
    padding: 0;
  }
  .action-title {
    padding: 0 8px;
  }
  .action-title,
  .action-btn {
    display: flex;
    width: $action-width;
    @include mobile-xl {
      justify-content: flex-end;
    }
  }
}
.action-table-row.with-border {
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid $gray-2;
  &:last-child {
    border-bottom: unset;
    padding-bottom: 0;
  }
}

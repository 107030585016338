@import 'src/theme.scss';

.wrapper {
  font-family: $font-family;
  border: 2px solid $gray-2;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 255px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  margin-bottom: 11px;
  cursor: pointer;
  order: 1;
  transition: all .4s ease-in-out;
  background-color: $white;
  &:hover, &.selected {
    border-color: $primary;
  }
  @include tablet-portrait {
    width: 100%;
    max-width: unset;
  }
}

@import 'src/theme.scss';

.slider_wrapper {
  position: relative;
  user-select: none;

  .slider_prev_container,
  .slider_next_container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;

    &.small:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .slider_prev_container {
    left: 0;
    &.small:before {
      background: linear-gradient(90.79deg, #ffffff 32.17%, rgba(255, 255, 255, 0) 99.39%);
      z-index: 1;
    }
  }

  .slider_next_container {
    right: 0;

    &.small:before {
      background: linear-gradient(270.79deg, #ffffff 32.17%, rgba(255, 255, 255, 0) 99.39%);
    }
  }

  .slider_prev,
  .slider_next {
    position: absolute;
    top: 50%;
    z-index: 10;
    background: $white;
    opacity: 0.7;
    border-radius: 50%;
    border: 1px solid #d3d3d3;
    box-sizing: content-box;

    &.large {
      width: 48px;
      height: 48px;
      margin-top: -24px;
      .slider_button_icon {
        font-size: 48px;
      }
    }

    &.small {
      width: 32px;
      height: 32px;
      margin-top: -16px;
      .slider_button_icon {
        font-size: 32px;
      }
    }

    &:hover {
      opacity: 1;
      cursor: pointer;

      .slider_button_icon {
        opacity: 1;
      }
    }

    .slider_button_icon {
      opacity: 0.5;
      color: $primary;
    }
  }

  .slider_prev {
    left: 0;
  }

  .slider_next {
    right: 0;
  }
}

.slider_container {
  width: 100%;
}

.slider_scrollable_container {
  transition: all 0.5s ease-out 0s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: flat;
  will-change: transform;
  display: table;
  -webkit-overflow-scrolling: touch;
}

.slider_list {
  display: flex;

  .slider_item {
    max-width: 400px;
  }
}

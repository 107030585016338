@import 'src/theme.scss';
.modal_content {
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  @include mobile-xl {
    padding: 0;
  }
  p {
    font-size: $text-size-xl;
    text-align: center;
    color: $black;
  }
  @include mobile-xl {
    height: 100%;
    justify-content: center;
  }
}
.modal_confirm_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include mobile-xl {
    width: 100%;
  }
  .confirm_btn {
    width: 100%;
  }
}

@import 'src/theme.scss';
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  button {
    min-width: 150px;
  }
  @include mobile-xl {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }
}
.input {
  flex: 1 70%;
  margin-right: 10px;
  @include mobile-xl {
    flex: 1 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.submit {
  position: relative;
  @include mobile-xl {
    button {
      width: 100%;
    }
  }
  .check_icon {
    font-size: 16px;
    margin-left: 8px;
  }
}
.buttonProgress {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
}

.apply_btn {
  height: 64px !important;
}

@import 'src/theme.scss';
.wrapper {
  padding: 60px 15%;
  background-color: $white;
  flex: 1;
  @include large-desktop {
    padding-left: 20%;
    padding-right: 20%;
  }
  @include laptop {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include tablet {
    padding-left: 48px;
    padding-right: 48px;
  }
  @include tablet-portrait {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include mobile-xl {
    padding-left: 0;
    padding-right: 0;
    background-color: $gray-3;
    padding-top: 15px;
  }
}

@import 'src/theme.scss';

.rewards_wrapper {
  display: flex;
  margin: 45px auto 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 465px;

  @include tablet {
    overflow: hidden;
    max-width: 658px;
    height: 465 * 0.8px;
    min-height: 405 * 0.8px;
  }

  @include tablet-portrait {
    height: 465 * 0.7px;
    min-height: 405 * 0.7px;
    margin: auto;
  }

  @include mobile-xl {
    height: 465 * 0.4px;
    min-height: 405 * 0.4px;
    margin: auto;
  }

  @include mobile {
    height: 465 * 0.25px;
    min-height: 405 * 0.25px;
  }
}

.rewards_card {
  width: 643px;
  height: auto;
  min-height: 405px;
  background-color: $gray-4;
  -webkit-print-color-adjust: exact;
  border-radius: 24px;
  text-align: left;
  position: relative;
  z-index: 0;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $white;
  @include tablet {
    transform-origin: center;
    transform: scale(0.8);
  }
  @include tablet-portrait {
    transform: scale(0.7);
  }

  @include mobile-xl {
    transform: scale(0.4);
  }

  @include mobile {
    transform: scale(0.25);
  }
  .header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    h2 {
      color: $white;
      font-size: 75px;
      padding-left: 25px;
      line-height: 75px;
    }
  }
  .rewards_card_bg {
    position: absolute;
    z-index: -1;
    right: 0;
    height: 100%;
  }

  .rewards_card_bg_pic {
    position: absolute;
    right: -1px;
    bottom: -1px;
  }

  .card_holder {
    font-family: $font-family-gothic;
    font-weight: 700;
    font-size: $header-size-xl + 20px;
    text-transform: capitalize;
    word-break: break-word;
    margin-top: auto;
    line-height: 48px;
    margin-bottom: 16px;
    z-index: 1;
    max-width: 65%;
  }

  .rewards_id {
    font-size: $header-size-xl + 2px;
    font-weight: 700;
    font-family: $font-family-gothic;
  }

  .contacts {
    margin-top: 21px;
    padding-bottom: 20px;

    p {
      text-transform: none;
      font-weight: normal;
      font-size: $text-size-xl;
      padding-bottom: 4px;
      line-height: normal;
      word-break: break-word;
    }
  }
}

@media print {
  .rewards_wrapper {
    display: block;
    margin: 0;
    min-height: 405px;
  }
  .rewards_card {
    width: 643px;
    min-height: 405px;
    transform-origin: left top;
    transform: scale(0.5);
    color: $white;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    .card_holder {
      font-size: 24pt;
    }

    .rewards_id {
      font-size: 13.5pt;
    }

    .contacts {
      p {
        font-size: 10.5pt;
      }
    }
  }
}

.modal_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .print_btn {
    width: 100%;
    max-width: 500px;
  }
}

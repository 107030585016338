@import 'src/theme.scss';
.MuiFormControlLabel-root.form_choose_btn_root {
  color: $gray;
  height: 76px;
  border: 2px solid $gray-2;
  padding: 0 16px 0 16px;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin: 0;
  font-weight: 900;
  transition: border 0.3s ease-out;
  &:hover {
    border-color: $gray;
    & .MuiRadio-root {
      color: $gray;
    }
    & .checkbox_root .checkbox_icon {
      &:not(.checkbox_icon_checked) {
        border-color: $gray;
      }
    }
  }
  @include mobile-xl {
    padding: 0 16px;
  }
  @include mobile {
    padding: 0 8px;
  }
  .image_control_wrapper {
    .image_control_icon_wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
    .image_control_img {
      width: 60px;
      height: 60px;
      background-color: $gray-3;
    }
    .image_control_checkbox {
      display: none;
    }
  }
}
.MuiFormControlLabel-root.form_choose_btn_root .form_choose_btn_label {
  padding-left: 8px;
  height: 100%;
  @include mobile-xl {
    padding-left: 8px;
  }
  .choose_btn_label_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    height: 100%;
    justify-content: space-between;
    .choose_btn_info_section {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      line-height: 18px;
      .choose_btn_description_wrapper {
        color: $gray;
        font-size: $text-size-lg;
        font-family: $font-family-gothic;
        align-self: flex-end;
        padding-left: 5px;
      }
    }
    .choose_btn_price_section {
      display: flex;
      align-items: flex-end;
      padding-bottom: 16px;
    }
    .choose_btn_price {
      font-family: $font-family-gothic;
      font-weight: 700;
    }
  }
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiRadio-root {
  padding: 0;
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiRadio-root {
  transition: 0.3s ease-out;
  color: $gray-2;
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiRadio-root.Mui-checked.Mui-disabled {
  color: $gray;
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiRadio-root.Mui-disabled {
  background-color: $gray-2;
  color: $gray;
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiRadio-root.Mui-checked {
  color: $primary;
}
.MuiFormControlLabel-root.form_choose_btn_root .choose_btn_price_selected {
  color: $primary;
}
.MuiFormControlLabel-root.form_choose_btn_root .choose_btn_price_disabled {
  color: $gray;
}
.MuiFormControlLabel-root.form_choose_btn_root.choose_btn_selected {
  border-color: $primary;
}
.MuiFormControlLabel-root.form_choose_btn_root .form_choose_btn_label {
  width: 100%;
}
.MuiFormControlLabel-root.form_choose_btn_root .choose_btn_label {
  font-size: $text-size-sm;
  font-weight: 900;
  color: $black;
  text-transform: uppercase;
}
.MuiFormControlLabel-root.form_choose_btn_root.choose_btn_selected {
  border-color: $primary;
  & .choose_btn_label {
    color: $primary;
  }
}
.MuiFormControlLabel-root.form_choose_btn_root.choose_btn_disabled {
  border-color: $gray;
  background-color: $gray-3;
  & .choose_btn_label {
    color: $gray;
  }
}
.MuiFormControlLabel-root.form_choose_btn_root .MuiFormControlLabel-label.Mui-disabled {
  color: $gray;
}

@import 'src/theme.scss';

.checkout_container {
  height: 100%;
  .checkout_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .notification_title {
      font-size: $header-size-md;
      text-transform: initial;
      padding-bottom: 10px;
      color: $black-1;
      font-family: $font-family;
      .logo {
        display: inline-block;
        height: 18px;
        margin-right: 2px;
      }
    }

    .notification_description {
      color: $gray;
      padding-bottom: 40px;
      font-size: $text-size-md;
      line-height: 18px;
    }

    .MuiButtonBase-root.btn_primary {
      height: 60px;
      margin-bottom: 20px;
    }
    button:last-child {
      margin-top: 32px;
    }
    button + button {
      margin-top: 18px;
    }
  }
}
.layout {
  .text_button {
    font-family: $font-family-gothic;
    padding: 0;
  }
}

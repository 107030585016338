@import 'src/theme.scss';

.modal_content {
  overflow: auto;
  padding: 0 20px;

  @include mobile-xl {
    padding: 0;
  }
  .section {
    padding: 0;
    .section_hr {
      margin-bottom: 0;
    }
    @include mobile-xl {
      padding: 0;
    }
  }

  .description {
    margin-top: -15px;
    padding-bottom: 20px;
    color: $black;
  }
}
.btn_label {
  margin-right: 8px;
}
.modal_footer {
  display: flex;
  justify-content: center;
  width: 100%;

  .submit_btn {
    width: 50%;
    min-width: 200px;
    @include mobile-xl {
      width: 100%;
      min-width: auto;
    }
  }

  .spinner {
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

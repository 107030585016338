@import 'src/theme.scss';

.form_body_wrapper {
  padding: 0 25px 40px;

  .form_body {
    margin: 0 auto;
    max-width: 366px;

    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }

    .form_error_container {
      margin-bottom: 15px;
    }

    .help_message {
      color: $gray;
      padding: 10px 0 16px;
      line-height: 16px;
    }
  }
}

.form_footer {
  background-color: $gray-3;
  position: relative;
  padding: 24px 25px;
  width: 100%;
  font-size: $text-size-md;
  display: flex;
  justify-content: center;

  button {
    height: 64px;
    width: 100%;
    max-width: 366px;
    position: relative;
  }

  .button_spinner {
    position: absolute;
    margin-top: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;

    svg {
      color: $white;
    }
  }
}

@import 'src/theme.scss';
.handoff_mode_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $text-size-md;
  text-transform: capitalize;
  color: $gray;
}

.select_handoff_mode {
  display: flex;
  align-items: center;
  font-size: 14px;
  .handoff_title {
    margin-right: 5px;
  }
}

.handoff_mode_info,
.select_handoff_mode {
  .handoff_mode_link {
    min-width: auto;
    padding: 0;
    text-transform: capitalize;
    font-size: $text-size-md;
    text-decoration: underline;
    font-weight: 400;
  }
}

@import "src/theme.scss";
@import "src/mixins.scss";

$sm_column_width: 150px;

.invite_list_container {
    margin-top: -24px;

    .header,
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
    }

    .header {
        .column_date,
        .column_mail,
        .column_status {
            color: $gray;
            font-size: $text-size-lg;
            font-family: $font-family-gothic;
        }
    }

    .row {
        .column_date,
        .column_mail {
            font-weight: 400;
        }

        .column_date,
        .column_mail,
        .column_status {
            font-size: $text-size-md;
            
        }

        .column_mail {
            width: calc(100% - #{$sm_column_width});
            @include block-ellipsis(21px, 1);
            margin: 0;
            margin-right: 10px;
            overflow-wrap: break-word;
        }
    }

    .left_columns {
        display: flex;
        width: calc(100% - #{$sm_column_width});
    }

    .column_status,
    .column_date {
        width: $sm_column_width;
    }

    @include tablet-portrait {
        .row {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
        }

        .header {
            .column_status {
                display: none;
            }
        }

        .left_columns {
            width: 100%;
        }
    }

    @include mobile-xl {
        .row {
            height: 84px;

            .left_columns {
                flex-direction: column;
            }

            .column_mail {
                width: 100%;
            }
        }

        .header {
            display: none
        }
    }
}

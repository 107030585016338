@import 'src/theme.scss';
$height-search-wrapper: 144px;
$height-tabs-wrapper: 56px;
.wrapper.handheld_mode {
  flex-direction: column;
}

.wrapper {
  display: flex;
  height: calc(100vh - #{$page-header-height});
  @include laptop {
    height: calc(100vh - #{$page-header-height-tablet});
  }
  @include tablet {
    height: calc(100vh - #{$page-header-height-tablet} - #{$height-tabs-wrapper});
  }
  @include mobile {
    height: calc(100vh - #{$page-header-height-mobile} - #{$height-tabs-wrapper});
  }
  :global(.MuiBackdrop-root) {
    z-index: 10000;
  }
}
.left_panel {
  flex: 0 1 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $gray-2;
}
.left_panel.handheld_mode {
  flex: 0 0 auto;
}
.left_panel.handheld_mode.active_tab {
  flex: 1 1 100%;
  // height: calc(100% - #{$height-tabs-wrapper});
  height: calc(100vh - #{$page-header-height-tablet} - #{$height-search-wrapper} - #{$height-tabs-wrapper});
}

.right_panel {
  flex: 1 1 auto;
}
.right_panel.handheld_mode {
  flex: 1 1 100%;
  display: none;
}
.right_panel.handheld_mode.active_tab {
  flex: unset;
  display: block;
  // height: calc(100vh - #{$page-header-height-tablet} - #{$height-search-wrapper} - #{$height-tabs-wrapper});
  height: calc(100% - #{$height-tabs-wrapper});
  position: relative;
}
.handheld_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.tabs {
  background-color: $white;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
}
.tab {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;

  button {
    &:global(.btn_primary_text) {
      width: 100%;
      text-decoration: none;
    }
  }
  &:first-child {
    border-right: 1px solid $gray-2;
  }
}
.locations {
  padding: 10px 24px;
  overflow-y: auto;
  height: 100%;
  scroll-behavior: smooth;
}
.locations.handheld_mode {
  display: none;
}
.locations.handheld_mode.active_tab {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.restaurants_list_spinner_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

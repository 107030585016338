@import 'src/theme.scss';
//Primary Button
.MuiButtonBase-root.btn_primary {
  border-radius: 4px;
  box-shadow: none;
  min-height: 54px;
  padding: 12px 24px;
  font-weight: 700;
  font-family: $font-family-gothic;
  @include mobile-xl {
    padding: 8px;
  }
}
.MuiButtonBase-root.btn_primary.btn_primary_filled {
  border-radius: 0;
  height: 100%;
  padding: 0;
  width: 100%;
  display: inline-flex;
}
.MuiButtonBase-root.btn_primary_sizeSmall.btn_primary_label {
  font-size: $text-size-sm;
}
.MuiButton-label.btn_primary_label .btn_primary_content {
  display: inline-flex;
  align-items: center;
  font-size: $text-size-extra;
  line-height: 24px;
  &.mac_platform.with_icon {
    padding-top: 3px;
    padding-bottom: 0;
    line-height: 1em;
  }
}
.MuiButtonBase-root.btn_primary_contained {
  &:hover {
    background-color: $primary-hover;
    box-shadow: none;
  }
  &:active {
    background-color: $primary-active;
    box-shadow: none;
  }
}
.MuiButtonBase-root.MuiButton-root:hover.Mui-disabled {
  background-color: $gray;
}
.MuiButtonBase-root.MuiButton-root:hover.Mui-disabled.btn_primary_outlined {
  background-color: $gray-2;
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: $gray-2;
}
.MuiButtonBase-root.btn_primary_outlined {
  background-color: $white;
  border: 2px solid $primary;
  &:hover {
    background-color: $white;
    border: 2px solid $primary-hover;
    color: $primary-hover;
  }
  &:active {
    background-color: $white;
    border: 2px solid $primary-active;
    color: $primary-active;
  }
}
.MuiButtonBase-root.btn_primary.btn_primary_sizeSmall {
  padding: 12px 8px;
  border-radius: 4px;
  font-size: $text-size-lg;
  height: 20px;
}
.MuiButtonBase-root.btn_primary.btn_primary_sizeLarge {
  font-size: $text-size-extra;
  padding: 14px 34px;
  border-radius: 4px;
  height: 64px;
  @include tablet {
    font-size: $text-size-lg;
    padding: 9px 25px;
    height: 55px;
  }
}
.MuiButton-contained.btn_primary_contained.btn_primary_disabled {
  background-color: $gray;
  color: $gray-2;
}
.MuiButtonBase-root.btn_primary_outlined.btn_primary_disabled {
  background-color: $gray-3;
  border: 2px solid $gray;
  color: $gray;
}
.MuiButton-endIcon.btn_primary_endIcon {
  margin-left: 8px;
}

//Secondary Button
.MuiButtonBase-root.btn_primary_text {
  font-family: $font-family-gothic;
}

.MuiButtonBase-root.btn_primary_text.btn_primary_text_sizeLarge {
  font-size: $text-size-extra;
  font-weight: 700;
  & .btn_primary_text_label {
    font-size: $text-size-extra;
  }
}
.MuiButtonBase-root.btn_primary_text_sizeSmall {
  min-width: unset;
  text-transform: unset;
  & .btn_primary_text_label {
    font-size: $text-size-lg;
    font-weight: 400;
  }
}
.MuiButtonBase-root.btn_primary_text {
  background-color: unset;
  font-size: $text-size-lg;
  font-weight: 600;
  font-family: $font-family;
  @include tablet {
    font-size: $text-size-md;
  }
  &:hover {
    background-color: unset;
    text-decoration: underline;
    box-shadow: none;
    color: $primary-hover;
  }
  &:active {
    background-color: unset;
    text-decoration: underline;
    color: $primary-active;
    box-shadow: none;
  }
}

//Primary Icon Button
.MuiButtonBase-root.btn_icon.primary {
  border-color: $primary;
  background-color: $primary;
  color: $white;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
      color: $white;
    }
  }
  &:active {
    background-color: $primary-active;
    border-color: $primary-active;
    color: $white;
  }
}
.MuiButtonBase-root.btn_icon.secondary {
  border-color: $gray;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $gray;
      color: $white;
    }
  }
  &:active {
    background-color: $gray;
    color: $white;
  }
}
.MuiButtonBase-root.btn_icon {
  border: 2px solid;
  height: 40px;
  width: 40px;
  transition: 0.4s ease-out;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.MuiIconButton-root.Mui-disabled.btn_icon_disabled {
  background-color: $gray-2;
  color: $gray;
  border-color: $gray;
}
.MuiButtonBase-root.btn_icon.btn_icon_sizeSmall {
  height: 24px;
  width: 24px;
  .MuiSvgIcon-root {
    font-size: $text-size-xl;
  }
}

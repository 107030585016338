@import 'src/theme.scss';

.account_details {
  .account_header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    .header {
      h3.title {
        line-height: 40px;
        @include mobile-xl {
          font-size: $header-size-extra - 5px;
        }
        @include mobile {
          font-size: $header-size-xl + 5px;
        }
      }
    }
    @include mobile-xl {
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }
  .card_details {
    background-color: $primary;
    border-radius: 8px;
    padding: 11px 8px;
  }

  .card_info {
    background-color: $white;
    border-radius: 8px;
    padding: 32px 24px 0 24px;

    @include mobile-xl {
      padding: 16px 14px 0 14px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    button.logout_btn {
      font-weight: 700;
      font-family: $font-family-gothic;
      padding: 0;
    }
  }
}

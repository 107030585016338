@import 'src/theme.scss';
.copy_link_container {
  display: flex;

  .copy_link_input {
    flex: 1;
  }

  .copy_link_button {
    margin-left: 27px;
    font-family: $font-family-gothic;
    font-size: $text-size-xl;
    padding: 0;
  }
}
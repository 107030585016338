@import 'src/theme.scss';

.handoff_mode_wrap,
.modal_footer {
  width: 80%;
  margin: 0 auto;
  &__mobile {
    width: 100%;
  }
}

.handoff_mode_wrap {
  margin-bottom: 8px;

  &__mobile {
    margin-top: 16px;
  }

  .use_saved_address_button {
    font-family: $font-family-gothic;
  }
  .buttonGroup {
    display: flex;
    border: 2px solid $primary;
    border-radius: 4px;
    margin-bottom: 42px;

    button {
      letter-spacing: 0.05rem;
      font-weight: 600;
      background-color: $white;
      color: $gray;
      margin: 2px;
      padding: 0;
      width: 100%;

      .button_content {
        display: flex;
        align-items: center;
      }

      .icon {
        margin-left: 4px;
        width: 20px;
        height: 20px;
        &.mac_platform {
          padding-bottom: 3px;
        }
        &__disabled {
          path {
            fill: $gray-2;
          }
        }
      }

      &.active,
      &.active:hover {
        background-color: $primary;
        color: $white;

        .icon path {
          fill: $white;
        }
      }

      &:not(.active):hover {
        color: $gray;
        background-color: $white;
      }
    }
  }

  .handoff_mode_btn {
    margin: 2px;
  }

  .default_address {
    margin-top: 16px;
  }

  .use_saved_address_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
}

.modal_spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal_content__loading,
.modal_footer__loading {
  opacity: 0.6;
}

.arrow_icon {
  &:hover {
    cursor: pointer;
  }
}

.saved_addresses_button_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 0;
  }

  &__mobile {
    margin-top: 10px;
    font-size: $text-size-md;
  }
}

.card_wrapper {
  margin-bottom: 10px;
  cursor: pointer;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    line-height: 16px;
    margin-top: 5px;
  }

  .card_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    &.no_save_address {
      padding-bottom: 0;
      letter-spacing: 0.03em;
    }
  }
}

.address_list {
  &_wrap {
    width: 100%;
    margin-top: 16px;
  }
  max-height: 270px;
  width: 100%;
  overflow-y: auto;
}

.modeButtonWrap {
  display: flex;
  align-items: center;
}

.icon_arrow {
  margin-left: 10px;
  margin-bottom: 2px;
}
.modal {
  @include mobile-xl {
    margin: 0;
    width: 100%;
  }
}

.address_list_wrap {
  .divider {
    margin-bottom: 16px;
  }
}

.special_instructions {
  .divider {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.checkbox {
  margin-top: 24px;
}

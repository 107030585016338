@import 'src/theme.scss';

.search_container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .search_btn {
    width: 54px;
    height: 54px;
    padding: 8px;
    min-width: unset;
    min-height: unset;
    border-color: $white;

    &:hover, &:active {
      border-color: $white;
    }

    @include mobile {
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }

  @include mobile-xl {
    display: block;
    position: unset;
  }
}

.search_input_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 54px;
  border: 1px solid $primary-light;
  border-radius: 4px;
  background: $white;
  color: $primary;
  padding: 0;
  transition: padding 500ms, width 500ms;
  width: 1px; // fix for Safari
  overflow: hidden;
  opacity: 0;

  @include mobile-xl {
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 0;
    height: 1px;
    padding: 0 12px;
    transition: padding 500ms, height 500ms;
  }

  &.show {
    width: 430px;
    opacity: 1;
    padding: 4px 12px;

    @include tablet {
      width: 260px;
    }

    @include mobile-xl {
      width: 100%;
      height: 55px;
    }
  }
}

.search_input {
  width: 100%;
  border: none;
  font-size: $text-size-xl;
  line-height: 22px;
  height: 100%;
  padding: 0 4px;
  font-family: $font-family;
  outline: none;

  &::-ms-clear {
    display: none;
  }
}

@import 'src/theme.scss';
.wrapper {
  border: 2px solid $primary;
  border-radius: 4px;
  padding: 40px;
  margin-bottom: 16px;
  @include mobile-xl {
    padding: 20px;
  }
  .header_wrapper {
    .header {
      .title {
        font-size: $text-size-xl + 2px;
      }
    }
  }
  p {
    color: $black;
    font-size: $text-size-md;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@import 'src/theme.scss';

.captcha {
  margin-bottom: 10px;
}
.form_alert {
  margin-bottom: 15px;

  .alert_message {
    a {
      color: $black;
      text-decoration: underline;
    }
  }
}

.content_form {
  display: flex;
  flex-direction: column;
  height: inherit;
  max-width: 366px;
  margin: 0 auto;

  .form_signup-button > button {
    height: 64px;
    width: 100%;
  }

  .input_wrapper {
    width: 100%;

    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }

    .password_adornment_icon {
      &:hover {
        .MuiIconButton-label {
          background-color: transparent;
        }
      }
    }
  }

  .form_first-name,
  .form_last-name,
  .form_email,
  .form_phone,
  .form_birthday,
  .form_password,
  .form_confirm-password {
    margin-bottom: 10px;
  }

  .help_message {
    padding-bottom: 16px;
    color: $gray;
  }

  .form_favorite_location {
    border-bottom: 1px solid $gray-2;
    margin-top: 28px;

    .MuiButtonBase-root.btn_primary.btn_primary_sizeLarge {
      padding: 9px 30px;
    }

    .label {
      border-bottom: 1px solid $gray-2;
      padding-bottom: 10px;
      margin-bottom: 24px;
      font-size: $text-size-extra;
    }

    .description {
      padding-bottom: 15px;
    }
  }

  .form_checkbox {
    margin: 24px 0;
    .form_label-checkbox {
      font-size: 14px;
      line-height: 1;

      .form_label-btn {
        text-decoration: underline;
      }

      .MuiButtonBase-root.btn_primary_text {
        text-transform: lowercase;
        text-decoration: underline;
        padding: 0;
      }
    }

    .MuiInputBase-root.input_root {
      display: none;
    }
  }

  .MuiFormControlLabel-root.form_checkbox_root {
    margin-right: 0;
    display: inline-flex;
    align-items: center;
  }

  .form_signup-button {
    position: relative;

    .button_spinner {
      position: absolute;
      margin-top: 3px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;

      svg {
        color: $white;
      }
    }
  }
}

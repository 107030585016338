@import 'src/theme.scss';
.Toastify {
  .Toastify__toast-container {
    width: 350px;
    border-radius: 4px;
  }
  .Toastify__toast {
    padding: 0;
    box-shadow: 0px 0px 10px rgba($gray, 0.65);
    border-radius: 4px;
    margin-bottom: 8px;
  }
}
.notification_content {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 2px solid $white;
  border-radius: 4px;
  p {
    font-size: $text-size-lg;
    font-weight: bold;
    margin-left: 16px;
    font-family: $font-family-gothic;
  }
}
.Notify_container.success {
  background: $success;
  color: $white;
  p {
    color: $white;
  }
}
.Notify_container.info {
  background: $gray-3;
  color: $black;
  p {
    color: $gray;
  }
}
.Notify_container.warning {
  background: $warning;
  color: $black;
  p {
    color: $black;
  }
}
.Notify_container.error {
  background: $error;
  color: $white;
  p {
    color: $white;
  }
}

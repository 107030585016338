@import 'src/theme.scss';

.modal_content {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 55px auto;
    max-width: 486px;

    p {
      font-size: $text-size-xl;
      line-height: 1.5em;
      color: $black;
      margin: 24px 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .backToHome {
        margin-top: 10px;
      }
    }
  }
}

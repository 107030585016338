@import 'src/theme.scss';

.container {
  padding-bottom: 30px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
  }
  .name {
    font-weight: 600;
    font-size: $text-size-md;
    margin: 4px 0;
  }
  .date {
    color: $gray;
    font-size: $text-size-md;
  }
  .no_data {
    color: $gray;
    font-size: $text-size-xl;
    padding-top: 10px;
    padding-bottom: 16px;
    text-align: center;

    .place_order_link {
      font-weight: bold;
      text-transform: unset;
      font-size: $text-size-xl;
      padding: 0;
      top: -1px;
    }
  }
  .cell_actions {
    display: flex;
    min-width: 140px;
    @include mobile-xl {
      justify-content: flex-end;
    }
  }
  .btn {
    text-transform: inherit;
    font-size: $text-size-md;
    font-weight: 400;
  }
  .delete {
    color: $gray;
  }
}

@import 'src/theme.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  max-height: 500px;
  min-height: 300px;
  @include tablet-portrait {
    flex-direction: column;
    width: 100%;
    max-height: unset;
    min-height: unset;
  }
}
.panel {
  flex: 0 255px;
  margin-right: 60px;
  @include tablet {
    margin-right: 30px;
  }
  @include tablet-portrait {
    flex: 1 100%;
    width: 100%;
    margin-right: 0;
  }
}
.body {
  flex: 1 auto;
  @include tablet-portrait {
    flex: 1 100%;
    width: 100%;
    margin-right: 0;
  }
}
.button_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  button {
    padding: 0;
    min-width: unset;
    display: inline-block;
    text-align: right;
    font-family: $font-family-gothic !important;
  }
  @include mobile-xl {
    flex-direction: row;
    margin-top: 16px;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @include mobile-xl {
    justify-content: center;
  }
}
.header_card {
  font-weight: 500;
  font-size: $text-size-md;
  line-height: 20px;
  color: $black;
  margin-bottom: 8px;
}
.description {
  font-weight: normal;
  font-size: $text-size-sm;
  line-height: 18px;
  color: $gray;
}
.balance {
  font-size: $text-size-md;
  line-height: 24px;
  color: $black;
}
.cards_wrapper {
  overflow-y: auto;
  max-height: 388px;
  min-height: 300px;
  @include tablet-portrait {
    max-height: unset;
    min-height: unset;
    overflow-y: inherit;
  }
}

.save_btn_icon {
  margin-left: 8px;
}

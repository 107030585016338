@import 'src/theme.scss';

.modal_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 24px;
  @include mobile-xl {
    padding: 0 16px 16px 16px;
  }

  p {
    margin: 0;
    font-weight: 400;
    text-transform: unset;
    font-size: $header-size-md;
    line-height: 22px;
    color: $black;
    text-align: center;
  }

  .delete_account_btn {
    margin: 14px 0;
  }

  .back_btn {
    @include mobile-xl {
      width: 100%;
    }
  }
}
p.warning {
  color: $primary;
  padding-top: 16px;
  font-size: $text-size-xl;
  font-weight: 800;
  @include mobile-xl {
    font-size: $text-size-lg;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text_btn {
    font-family: $font-family-gothic;
    font-size: $text-size-xl + 2px;
  }
  .submit_btn {
    position: relative;
    padding: 0;
    font-family: $font-family-gothic;
    .spinner {
      position: absolute;
      left: 50%;
      top: 55%;
      padding: 0;
      width: auto;
      transform: translate(-50%, -50%);
    }
    @include mobile-xl {
      margin-bottom: 16px;
    }
  }
  @include mobile-xl {
    flex-direction: column;
  }
}

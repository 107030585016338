@import 'src/theme.scss';
.header_wrapper.not_found_title {
  .title_wrapper {
    padding: 8px 16px;
  }

  @media (min-width: #{$breakpoint-sm}) {
    .brush_before {
      transform: translateX(-40%);
    }

    .brush_after {
      transform: translateX(-60%);
    }
  }
}

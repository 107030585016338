@import 'src/theme.scss';

.container {
  margin: 24px 0 6px;

  .name_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .name_wrapper {
      display: flex;
      flex-direction: column;

      .name_label {
        color: $gray;
        font-family: $font-family;
        font-size: $header-size-sm;
        text-transform: unset;
        font-weight: 400;
        line-height: 18px;
      }

      .name {
        color: $gray;
        font-family: $font-family;
        font-size: $header-size-md;
        text-transform: unset;
      }
    }

    .edit_name_btn {
      text-transform: unset;
      font-size: $text-size-md;
      font-weight: 400;
      text-decoration: underline;
      padding: 0;
      line-height: 18px;
    }
  }

  .input_container {
    width: 100%;
  }

  .update_name_btn {
    width: 100%;
    margin-top: 8px;
    padding: 20px 0;

    .spinner {
      position: absolute;
      margin-top: 4px;
      padding: 0;
    }
  }
}

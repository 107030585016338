@import 'src/theme.scss';
.wrapper {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white;

  @include tablet {
    padding: 16px;
    flex-direction: row-reverse;
  }

  @include mobile-xl {
    flex-direction: column;
  }
}
.search_input {
  width: 100%;

  @include tablet-portrait {
    width: 80%;
  }

  @include mobile-xl {
    width: 100%;
  }
}
.options {
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: flex-end;

  @include tablet {
    margin-top: 0;
    margin-right: 8px;
  }

  @include mobile-xl {
    margin-top: 16px;
    margin-right: 0;
  }

  @include mobile {
    margin-top: 8px;
    align-items: center;
  }
  
}
.locations_btn {
  display: flex;
  flex: 1;
  align-items: center;
  .location_button {
    white-space: nowrap;
    padding: 0;
    font-size: $text-size-lg;
    font-family: $font-family-gothic;
  }
  @include mobile {
    margin-top: 0;
    .location_button {
      font-size: $text-size-sm;
    }
  }
}
.end_adornment {
  display: inline-flex;
  color: $gray;
  &[data-active='true'] {
    color: $primary;
  }
  &:hover {
    cursor: pointer;
    color: $primary;
  }
}
.distance {
  display: flex;
  align-items: center;
  @include mobile-xl {
    justify-content: flex-end;
  }
}
.distance_label {
  text-align: right;
  padding-right: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $text-size-sm;
  color: $gray;
  max-width: 100px;
}
.wrapper .options {
  .distance_select {
    padding: 0;
    width: 105px;
    height: 40px;
    display: inline-flex;
    align-items: center;

    @include tablet {
      height: 56px;
    }

    @include mobile-xl {
      height: 40px;
    }

    legend {
      max-width: 0;
    }
    & > div {
      padding: 0 8px;
    }
    .distance_select_item {
      width: 100px;
    }
    .distance_select {
      width: 100px;
    }
  }
}

@import 'src/theme.scss';

.container {
  padding-bottom: 30px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .order_name {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    @include mobile-xl {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 4px;
    }
  }
  .label {
    color: $gray;
    background-color: $gray-2;
    font-size: $text-size-x-sm;
    font-weight: 800;
    text-transform: uppercase;
    padding: 4px;
    border-radius: 4px;
  }
  .name {
    font-weight: 600;
    font-size: $text-size-md;
    text-transform: uppercase;
  }
  .date {
    color: $gray;
    font-size: $text-size-md;
  }
  .no_data {
    color: $gray;
    font-size: $text-size-xl;
    padding-top: 10px;
    padding-bottom: 16px;
    text-align: center;

    .place_order_link {
      font-weight: bold;
      text-transform: unset;
      font-size: $text-size-xl;
      padding: 0;
      top: -1px;
    }
  }
  .cell_actions {
    display: flex;
    min-width: 140px;
    @include mobile-xl {
      justify-content: flex-end;
    }
  }
  .btn {
    text-transform: inherit;
    font-size: $text-size-md;
    font-weight: 400;
  }
}

@import 'src/theme.scss';
.btn_wrapper {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}
.footer_button {
  width: 100%;
}
.backdrop {
  width: 100%;
  height: 100%;
  background-color: $gray-3;
  opacity: .7;
  left: 0;
  top: 0;
  padding: 10px;
  display: block;
  position: absolute;
  z-index: 100;
}
.upsell_section:first-child {
  margin: 0;
}

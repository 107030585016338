@import 'src/theme.scss';
.wrapper {
  h4 {
    font-family: $font-family;
    text-transform: none;
    padding-bottom: 8px;
  }
  p {
    color: $gray;
    letter-spacing: 0.01em;
    font-size: $text-size-md;
  }
}

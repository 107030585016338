@import "src/theme.scss";
.wrapper {
    .form {
        width: 100%;
        max-width: 370px;
        margin: 0 auto;
    }
    .button {
      @include mobile-xl {
        width: 100%;
      }
    }
}

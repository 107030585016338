@import 'src/theme.scss';
@import 'src/mixins.scss';

.notification_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $global-notification-height;
  padding: 0 80px 0 24px;
  background-color: $white;
  z-index: 1150;

  .notification {
    @include block-ellipsis(none, 2);
    color: $black;
    font-size: $text-size-xl;
    margin: 0;
  }

  .link {
    text-transform: uppercase;
    margin-left: 24px;
    margin-bottom: 4px;
  }

  .close_btn {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
  }

  @include laptop {
    padding-left: 16px;

    .close_btn {
      right: 16px;
    }
  }

  @include mobile-xl {
    padding-right: 55px;

    .notification {
      font-size: $text-size-lg;
    }

    .link {
      font-size: $text-size-xl;
      margin-left: 10px;
    }

    .close_btn {
      width: 30px;
      height: 30px;
    }
  }
}

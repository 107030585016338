@import 'src/theme.scss';
.birthday_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  .birthday_label {
    margin-right: 16px;
    color: $gray;
    font-size: $text-size-md;
  }
  .birthday_input-month input,
  .birthday_input-day input,
  .birthday_input-year input {
    text-align: center;
    padding: 0;
  }
  .birthday_input-month label,
  .birthday_input-day label,
  .birthday_input-year label {
    width: 100%;
    margin-left: -50%;
    text-align: center;
    transform: translate(50%, 24px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(62.5%, 14px) scale(0.75);
    }
  }
  .birthday_input-year.MuiFormControl-root.MuiTextField-root {
    width: 130%;
  }
  .splitter {
    margin: 0 8px;
  }
}
.birthday_component {
  .MuiFormHelperText-root.birthday_input_helperText {
    width: 100%;
    margin-top: 5px;
    margin-left: 14px;
    margin-right: 14px;
  }
}

@import 'src/theme.scss';
.checkbox_root.PrivateSwitchBase-root-2 {
  margin: 8px;
  padding: 0;
}
.checkbox_root .checkbox_icon {
  border: 2px solid $gray;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 24px;
  height: 24px;
  background-color: $white;
  transition: border 0.3s ease-out;
  z-index: 1;
  & svg {
    font-size: 1.1rem;
    font-weight: 600;
  }
  &:hover {
    border-color: $primary;
  }
}
.checkbox_root .checkbox_icon_checked {
  color: $primary;
  border-color: $primary;
  background-color: $white;
}
.checkbox_root.Mui-disabled .MuiIconButton-label .checkbox_icon_checked {
  color: $gray;
  background-color: $gray-3;
  border-color: $gray-2;
}
.checkbox_root.Mui-disabled .MuiIconButton-label .checkbox_icon {
  color: $gray;
  background-color: $gray-2;
  border-color: $gray;
}
span.MuiTypography-body1 {
  font-family: inherit;
  font-size: $text-size-md;
  line-height: 24px;
}
.MuiFormControlLabel-root.form_checkbox_root {
  color: $black-1;
  align-items: flex-start;
  margin: 0;
  &:hover {
    .checkbox_icon {
      border-color: $primary;
    }
  }
}
.MuiFormControlLabel-root.form_checkbox_root .MuiFormControlLabel-label.Mui-disabled {
  color: $black-1;
}
.MuiFormControlLabel-root.form_checkbox_root .checkbox_root {
  margin-right: 16px;
  padding: 0;
  color: $gray-2;
}
.MuiFormControlLabel-root.form_checkbox_root .checkbox_root.MuiCheckbox-colorPrimary.Mui-checked {
  color: $primary;
}
.MuiFormControlLabel-root.form_checkbox_root .checkbox_root.MuiCheckbox-colorPrimary.Mui-disabled {
  color: $gray-2;
}

@import 'src/theme.scss';
.section {
  :global(.section_title) {
    white-space: nowrap;
  }
  .wrapper {
    h4 {
      font-family: $font-family;
      font-size: $header-size-md;
      line-height: 28px;
      font-weight: 800;
      margin-bottom: 8px;
      text-transform: none;
    }
    p {
      color: $gray;
      font-size: $text-size-md;
    }
  }
  .section {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .date {
    white-space: pre-line;
  }
}

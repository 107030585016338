@import 'src/theme.scss';
.MuiButtonGroup-root {
  button {
    width: 100%;
    letter-spacing: 0.05rem;
    font-weight: 600;
    background-color: $white;
    color: $primary;
    border-color: $gray-2;

    &.active,
    &.active:hover {
      background-color: $primary;
      color: $white;
    }

    &.btn_primary_contained:not(.active):hover {
      color: $primary;
      background-color: $white;
    }
  }
}
.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
  border-left-color: transparent;
}

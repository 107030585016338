@import 'src/theme.scss';

.modal_content {
  padding: 0 40px 24px;

  @include tablet {
    padding: 0;
  }
}

.start_order_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include mobile-xl {
    flex-direction: column;
  }

  .order_button {
    width: 100%;
    max-width: 260px;

    @include mobile-xl {
      margin-top: 15px;
    }
  }

  .button_progress {
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}

.footer {
  width: 100%;
  text-align: center;

  .button {
    width: 100%;
    max-width: 260px;
  }
}

@import 'src/theme.scss';

.section_wrapper {
  margin-bottom: 30px;
  margin-top: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile-xl {
    margin: 20px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.topped {
    margin-top: 0;
  }

  .uppercase_text {
    text-transform: uppercase;
  }

  .section_header {
    margin-bottom: 8px;

    &:not(.with_header_hr) {
      &:not(.with_description) {
        max-width: 70%;
      }
    }

    .section_title {
      display: flex;
      font-family: $font-family-gothic;
      text-transform: capitalize;
      text-align: left;
      font-size: $header-size-md + 2px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 0.05em;
    }

    .section_description {
      font-size: $text-size-md;
      line-height: 24px;
    }

    @include mobile-xl {
      .section_title {
        font-size: $header-size-md;
        line-height: 22px;
      }

      .section_description {
        line-height: 18px;
        font-size: $text-size-sm;
        padding-top: 8px;
      }
    }
  }

  .section_header.row_direction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .section_title {
      &.with_description {
        max-width: 35%;
      }
    }

    .section_description {
      width: 50%;
      text-align: right;
    }

    @include mobile-xl {
      flex-wrap: wrap;

      .section_title {
        flex: 1 100%;

        &.with_description {
          max-width: initial;
        }
      }

      .section_description {
        width: 100%;
        text-align: left;
      }
    }
  }

  .section_header.with_header_hr {
    .section_header_hr {
      flex-grow: 1;
      height: 1px;
      background-color: $gray-2;
    }

    .section_title {
      max-width: 35%;
    }

    .section_description {
      padding-left: 15px;
      width: auto;
    }

    @include mobile-xl {
      .section_header_hr {
        display: none;
      }

      .section_title {
        max-width: initial;
      }

      .section_description {
        padding-left: 0;
      }
    }
  }

  .section_hr {
    width: 100%;
    height: 1px;
    margin-bottom: 24px;
    background-color: $gray-2;
  }
}

@import 'src/theme.scss';
.MuiFormControlLabel-root.form_radiobox_root {
  color: $gray;
}
.MuiFormControlLabel-root.form_radiobox_root .MuiFormControlLabel-label.Mui-disabled {
  color: $gray;
}
.MuiFormControlLabel-root {
  .MuiRadio-root.radiobox_root {
    margin: 8px;
    padding: 0;
  }
}
.MuiFormControlLabel-root.form_radiobox_root .radiobox_root {
  color: $gray-2;
}
.MuiFormControlLabel-root.form_radiobox_root .radiobox_root.Mui-checked {
  color: $primary;
}
.MuiFormControlLabel-root.form_radiobox_root .radiobox_root.MuiRadio-colorPrimary.Mui-checked {
  color: $primary;
}
.MuiFormControlLabel-root.form_radiobox_root .radiobox_root.Mui-checked.Mui-disabled {
  color: $gray-2;
}
.MuiFormControlLabel-root.form_radiobox_root .radiobox_root.Mui-disabled {
  color: $gray-2;
}

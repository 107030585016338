@import 'colors.scss';
@import 'fonts.scss';

$font-family: 'Poppins-Regular', sans-serif;
$font-family-gothic: 'Poppins-Extra-Bold', sans-serif;
$font-family-museo: 'Poppins-Semi-Bold', sans-serif;
$text-color: $black;
$facebook-color: #226db3;
$facebook-color-hover: #297dcc;
$error-color: $red;
$warning-color: #ff8400;
$header-size-extra: 48px;
$header-size-xl: 28px;
$header-size-lg: 22px;
$header-size-md: 18px;
$header-size-sm: 14px;
$text-size-extra: 20px;
$text-size-xl: 18px;
$text-size-lg: 16px;
$text-size-md: 14px;
$text-size-sm: 12px;
$text-size-x-sm: 10px;
$text-size: $text-size-sm;
$global-notification-height: 68px;
$page-header-height: 79px;
$page-header-height-tablet: 72px;
$page-header-height-mobile: 62px;
//MEDIA-QUERY
$breakpoint-xxl: 1600px;
$breakpoint-xl: 1360px;
$breakpoint-lg: 1024px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 320px;
//MAP SIZE BOX
$map-info-box-width: 305px;

@mixin large-desktop {
  @media (min-width: #{$breakpoint-xxl}) {
    @content;
  }
}

@mixin laptop($additional-px: 0px) {
  @media (max-width: calc(#{$breakpoint-xl} + #{$additional-px})) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}
@mixin mobile-xl {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}
@mixin mobile-xl-horizontal {
  @media (max-width: #{$breakpoint-md + 200px}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}

:export {
  breakPointXl: $breakpoint-xl;
  breakPointLg: $breakpoint-lg;
  breakPointMd: $breakpoint-md;
  breakPointSm: $breakpoint-sm;
  breakPointXs: $breakpoint-xs;
  color_gray: $gray;
  color_gray1: $gray-1;
  color_gray2: $gray-2;
  color_gray3: $gray-3;
  color_primary: $primary;
  color_primary_darker: $primary-light;
  color_white: $white;
  color_black: $black;
  color_error: $error-color;
}

@import 'src/theme.scss';
.footer_wrapper {
  flex-shrink: 0;
  background-color: $primary;
  padding: 38px 98px 20px;
  @include laptop {
    padding: 38px 38px 20px;
  }
  @include mobile-xl {
    padding: 24px 24px 20px;
  }
  .footer_info_text {
    margin-bottom: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba($primary-light, 0.2);
    .footer_paragraph_offset:not(:first-child) {
      margin-top: 16px;
    }
    p {
      padding-bottom: 16px;
    }
    @include tablet {
      margin-bottom: 16px;
      padding-bottom: 0;
    }
  }
  .footer_logo {
    @include tablet {
      margin-bottom: 36px;
    }
    @include mobile-xl {
      margin-bottom: 24px;
      & > div {
        margin-left: 0;
      }
    }
  }
  .footer_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .footer_links {
      display: inline-flex;
      @include mobile-xl {
        flex-wrap: wrap;
      }
      a {
        color: $white;
        margin-right: 38px;
        font-weight: 600;
        font-size: $text-size-xl + 2px;
        text-transform: uppercase;
        font-family: $font-family-gothic;
        &:last-child {
          margin-right: 0;
        }
        @include laptop {
          margin-right: 24px;
        }
        @include tablet {
          font-size: $text-size-lg;
          margin-right: 16px;
        }
        @include mobile-xl {
          font-size: $text-size-md;
        }
      }
    }
  }
}

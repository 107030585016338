@import 'src/theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputGroupWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @include mobile-xl {
    margin-top: 0;
    flex-direction: column;
  }
}

.inputWrapper {
  @include mobile-xl {
    margin-bottom: 8px;
  }
}

.inlineInputItem {
  flex-basis: calc(33% - 2px);

  @include mobile-xl {
    margin-bottom: 8px;
  }
}

.checkboxWrapper {
  margin-top: 24px;
  padding-left: 2px;

  @include mobile-xl {
    margin-top: 8px;
  }

  :global(.MuiFormControlLabel-root.form_checkbox_root) {
    color: $black;
  }
}

.creditCardVeil {
  display: none;
}

.card-number-container {
  margin-top: 8px;

  @include mobile-xl {
    margin-top: 0;
  }

  &::before {
    content: 'Credit Card Number';
  }

  &.errorText {
    &::after {
      content: 'Credit card type is not supported';
    }
  }

  &.incomplete {
    &::after {
      content: 'Please enter correct card number';
    }
  }

  &.emptyField {
    &::after {
      content: 'Please enter card number';
    }
  }
}

.cvv-info {
  &::before {
    content: 'CVV';
  }

  &.errorText {
    &::after {
      content: 'Please enter cvv';
    }
  }
}

.card-number-container,
.cvv-info {
  box-sizing: border-box;
  height: 64px;
  position: relative;
  transition: border-color 0.4s ease-in-out;
  border: 1px solid $gray-2;
  border-radius: 4px;
  &:hover {
    border: 1px solid $gray;
  }
  &::before {
    color: $gray;
    display: block;
    font-family: $font-family;
    font-size: $text-size-md;
    left: 12px;
    letter-spacing: 0.01em;
    pointer-events: none;
    position: absolute;
    top: 20px;
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @include mobile-xl {
      font-size: $text-size-md - 1px;
    }
  }

  &:focus {
    border: 1px solid $gray;
  }

  &.floatedLabel {
    &::before {
      left: 11px;
      top: 12px;
      transform: scale(0.75);
    }
  }

  &.errorText,
  &.emptyField,
  &.incomplete {
    border: 1px solid $red;
    border-radius: 4px;
    margin-bottom: 19px;

    &::before {
      color: $red;
    }

    &::after {
      color: $red;
      font-size: $text-size-sm;
      left: 14px;
      letter-spacing: 0.4px;
      position: absolute;
      top: 68px;
    }
  }
}


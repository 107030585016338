@import 'src/theme.scss';
.wrapper {
  h4 {
    font-family: $font-family;
    text-transform: capitalize;
    padding-bottom: 8px;
  }
  p {
    color: $gray;
    letter-spacing: 0.01em;
    font-size: $text-size-md;
  }
  .empty_curbside_warning {
    background-color: rgba(255, 213, 115, 0.5);
    text-transform: none;
    font-family: $font-family;
    font-size: $text-size-md;
    font-weight: 500;
    line-height: 18px;
    color: $black-1;
    .link {
      font-weight: bold;
      margin-top: 7px;
    }
    .MuiAlert-icon {
      color: $black-1;
    }
  }
}
.curbside_field {
  color: $gray;
  letter-spacing: 0.01em;
  font-size: $text-size-md;
  text-transform: capitalize;
}
.container .link {
  font-size: $text-size-md;
  text-transform: capitalize;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  min-width: auto;
}

@import 'src/theme.scss';

$borderWidth: 2px;
$markerSize: 64px; // also change MAP_MARKER_HEIGHT in google map config
$iconSize: 42px;
$markerBorderColor: $primary;
.map_marker {
  top: -($markerSize / 2) - 45px; // Math.sqrt(2 * Math.pow(MAP_MARKER_HEIGHT / 2, 2))
  left: -$markerSize / 2;
  width: $markerSize;
  height: $markerSize;
  position: absolute;
  z-index: 3;
  will-change: transform;
  transition: transform 0.3s ease-in-out;
  .img_wrapper {
    position: absolute;
    width: $iconSize;
    height: $iconSize;
    z-index: 2;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
    }
  }
}
.map_marker::after {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: $markerSize;
  height: $markerSize;
  content: '';
  transform: rotateZ(-45deg);
  border: $borderWidth solid $markerBorderColor;
  background-color: $white;
  border-radius: 50% 50% 50% 50%;
}
.map_marker::before {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: $markerSize;
  height: $markerSize;
  content: '';
  transform: rotateZ(-45deg);
  border: $borderWidth solid $markerBorderColor;
  border-radius: 50% 50% 50% 0;
  background-color: $white;
  z-index: 1;
}
.map_marker_hover {
  transform: scale(1.1);
  cursor: pointer;
}

@import 'src/theme.scss';
.wrapper {
  padding: 40px;
  background-color: $gray-3;
  border-radius: 8px;
  color: $black;
  h2 {
    margin-bottom: 12px;
    text-transform: none;
    font-size: $header-size-xl;
    text-transform: uppercase;
    @include mobile-xl {
      font-weight: 600;
      font-size: $header-size-lg;
      line-height: 33px;
    }
  }
  p {
    font-size: $text-size-xl;
    color: $black;
  }
  @include mobile-xl {
    padding: 40px 24px;
  }
  .link {
    text-decoration: underline;
    font-size: $text-size-xl;
  }
}

.button {
  margin-top: 24px;
  @include mobile-xl {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

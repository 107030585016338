@import 'src/theme.scss';

.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .form_footer {
    font-size: $text-size-extra;
    font-family: $font-family-gothic;
    font-weight: bold;
    text-align: center;
    padding-top: 40px;
  }
}

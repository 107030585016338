@import 'src/theme.scss';
.container {
  .error_container {
    margin-bottom: 20px;
  }

  .description {
    margin-bottom: 12px;
    color: $gray;
    font-size: $text-size-md;
    font-weight: 500;
  }

  .emails {
    width: 100%;
  }

  .note {
    width: 100%;
    margin-top: 8px;
  }
}

@import 'src/theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  .link {
    font-size: $text-size-md;
    text-transform: capitalize;
    padding: 0;
    font-weight: 500;
    text-decoration: underline;
    min-width: auto;
  }
}

.header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    text-transform: uppercase;
    font-size: $text-size-extra;
    line-height: 24px;
    letter-spacing: 0.05em;
    font-weight: 700;

    @include mobile-xl {
      font-size: $text-size-lg;
    }
  }
}

.inputWrapper {
  flex-basis: calc(33% - 2px);

  @include mobile-xl {
    margin-bottom: 16px;
  }
}

.inputGroupWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.creditCardVeil {
  display: none;
}

.checkboxWrapper {
  margin-top: 26px;
  padding-left: 2px;

  :global(.MuiFormControlLabel-root.form_checkbox_root) {
    color: $black;
  }
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}
